var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
        },
        keydown: function($event) {
          return _vm.purchasingForm.errors.clear($event.target.name)
        }
      }
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-3" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("product.number")))
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.purchasingForm.number,
                    expression: "purchasingForm.number"
                  }
                ],
                staticClass: "form-control",
                attrs: { type: "text", value: "", name: "number" },
                domProps: { value: _vm.purchasingForm.number },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.purchasingForm, "number", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.purchasingForm,
                  "prop-name": "number"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-9" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("product.ean_number"))),
                _c("span", {
                  domProps: { textContent: _vm._s(_vm.curProduct) }
                })
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.purchasingForm.ean_number,
                    expression: "purchasingForm.ean_number"
                  }
                ],
                staticClass: "form-control",
                attrs: { type: "text", value: "", name: "ean_number" },
                domProps: { value: _vm.purchasingForm.ean_number },
                on: {
                  input: [
                    function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.purchasingForm,
                        "ean_number",
                        $event.target.value
                      )
                    },
                    _vm.searchProduct,
                    _vm.resetProduct
                  ]
                }
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.purchasingForm,
                  "prop-name": "ean_number"
                }
              })
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-6" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("product.price")))
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.purchasingForm.price,
                    expression: "purchasingForm.price"
                  }
                ],
                staticClass: "form-control",
                attrs: { type: "text", value: "", name: "price" },
                domProps: { value: _vm.purchasingForm.price },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.purchasingForm, "price", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: { "form-name": _vm.purchasingForm, "prop-name": "price" }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-6" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("product.expired_at")))
              ]),
              _vm._v(" "),
              _c("datepicker", {
                attrs: { bootstrapStyling: true },
                on: {
                  selected: function($event) {
                    return _vm.purchasingForm.errors.clear("expired_at")
                  }
                },
                model: {
                  value: _vm.purchasingForm.expired_at,
                  callback: function($$v) {
                    _vm.$set(_vm.purchasingForm, "expired_at", $$v)
                  },
                  expression: "purchasingForm.expired_at"
                }
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.purchasingForm,
                  "prop-name": "expired_at"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-6" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("product.date")))
              ]),
              _vm._v(" "),
              _c("datepicker", {
                attrs: { bootstrapStyling: true },
                on: {
                  selected: function($event) {
                    return _vm.purchasingForm.errors.clear("date")
                  }
                },
                model: {
                  value: _vm.purchasingForm.date,
                  callback: function($$v) {
                    _vm.$set(_vm.purchasingForm, "date", $$v)
                  },
                  expression: "purchasingForm.date"
                }
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: { "form-name": _vm.purchasingForm, "prop-name": "date" }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _vm.purchasingForm.inventory_check
          ? _c("div", { staticClass: "col-md-6" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", { attrs: { for: "" } }, [
                    _vm._v(_vm._s(_vm.trans("product.inventory_status")))
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.purchasingForm.inventory_status,
                        expression: "purchasingForm.inventory_status"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      value: "",
                      name: "inventory_status"
                    },
                    domProps: { value: _vm.purchasingForm.inventory_status },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.purchasingForm,
                          "inventory_status",
                          $event.target.value
                        )
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("show-error", {
                    attrs: {
                      "form-name": _vm.purchasingForm,
                      "prop-name": "inventory_status"
                    }
                  })
                ],
                1
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-info waves-effect waves-light pull-right",
              attrs: { type: "button" },
              on: { click: _vm.storePurchasing }
            },
            [_c("span", [_vm._v(_vm._s(_vm.trans("general.save")))])]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }