<template>
    <form @submit.prevent="storeSalesImport" @keydown="salesImportForm.errors.clear($event.target.name)">
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label for="">{{trans('product.date_sale')}}</label>
                    <datepicker v-model="salesImportForm.date" :bootstrapStyling="true" @selected="salesImportForm.errors.clear('date')" :disabledDates="disabledFn"></datepicker>
                    <show-error :form-name="salesImportForm" prop-name="date"></show-error>
                </div>
            </div>
        </div>
        <div class="row" v-if="salesImportForm.date">
            <div class="col-md-12">
                <!-- <h4 class="card-title">{{trans('product.import_file_sales')}}</h4> -->
                <upload-xls id="xls" :date-sales="salesImportForm.date" :upload-path="`/product/import-xls`" :remove-path="`/product/import-xls/remove`" @uploaded="updateXLS" @removed="updateXLS"></upload-xls>
            </div>                                
        </div>
        <!-- <div class="row">
            <div class="col-md-12">
                <button type="submit" class="btn btn-info waves-effect waves-light pull-right">
                    <span>{{trans('general.save')}}</span>
                </button>                
            </div>
        </div>-->       
    </form>
</template>
<script>
    import datepicker from 'vuejs-datepicker'
    import uploadXls from '../../components/upload-xls'
    
    export default {
        components: {datepicker,uploadXls},    
        data() {
            return {
                salesImportForm: new Form({
                    'ean_number' : '',
                    'price' : '0,00',
                    'number' : '1',
                    'type' : 's',
                    'date' : new Date(),
                }),
                curDate: '',
                disabledFn: {
                    customPredictor(date) {
                      if (date.toISOString().slice(0,10) >= new Date().toISOString().slice(0,10)) {
                        return true; // returns true if disabled
                      }
                    }
                  }
            };
        },
        mounted(){            
            var d = new Date();
            d.setDate(d.getDate() - 1);
            this.curDate = d.toISOString().slice(0,10);
            // this.salesImportForm.date = d.toISOString().slice(0,10);
            this.salesImportForm.date = d; // .toISOString().slice(0,10);
        },
        methods: {
            storeSalesImport(){
                
            },
            updateXLS(){
               
            },
            setCurDate(){
                console.log(this.curDate);
                this.curDate = this.salesImportForm.date.toISOString().slice(0,10);
            },
            getNow: function() {
                const today = new Date();
                const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
                // const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
                // const dateTime = date +' '+ time;
                this.curDate = today;
            }
        }
    }
</script>
