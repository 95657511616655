var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row page-titles" }, [
      _c("div", { staticClass: "col-md-12 col-12" }, [
        _c("h3", { staticClass: "text-themecolor m-b-0 m-t-0" }, [
          _vm._v(_vm._s(_vm.trans("product.edit_stock_history")))
        ]),
        _vm._v(" "),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c("router-link", { attrs: { to: "/home" } }, [
                _vm._v(_vm._s(_vm.trans("general.home")))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("li", { staticClass: "breadcrumb-item active" }, [
            _vm._v(_vm._s(_vm.trans("product.history")))
          ])
        ]),
        _vm._v(" "),
        _vm.stock_history_entries.total && !_vm.showFilterPanel
          ? _c(
              "button",
              {
                staticClass: "btn btn-info btn-sm pull-right m-r-5",
                on: {
                  click: function($event) {
                    _vm.showFilterPanel = !_vm.showFilterPanel
                  }
                }
              },
              [
                _c("i", { staticClass: "fas fa-filter" }),
                _vm._v(" " + _vm._s(_vm.trans("general.filter")))
              ]
            )
          : _vm._e()
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "container-fluid p-40" }, [
      _c(
        "div",
        { staticClass: "row" },
        [
          _c("product-sidebar", { attrs: { menu: "history", id: _vm.id } }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-sm-9" },
            [
              _c("transition", { attrs: { name: "fade" } }, [
                _vm.showFilterPanel
                  ? _c("div", { staticClass: "card" }, [
                      _c("div", { staticClass: "card-body" }, [
                        _vm.showFilterPanel
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-info btn-sm pull-right",
                                on: {
                                  click: function($event) {
                                    _vm.showFilterPanel = !_vm.showFilterPanel
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.trans("general.hide")))]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("h4", { staticClass: "card-title" }, [
                          _vm._v(_vm._s(_vm.trans("general.filter")))
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-4" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(_vm._s(_vm.trans("stock_history.type")))
                              ]),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.filterStockHistoryForm.type,
                                      expression: "filterStockHistoryForm.type"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: { name: "order" },
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.filterStockHistoryForm,
                                        "type",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("option", { attrs: { value: "" } }, [
                                    _vm._v("Alle")
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "i" } }, [
                                    _vm._v("Inventur")
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "p" } }, [
                                    _vm._v("eingekauft")
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "w" } }, [
                                    _vm._v("ausgebucht")
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "s" } }, [
                                    _vm._v("verkauft")
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "b" } }, [
                                    _vm._v("als verkauft markiert")
                                  ])
                                ]
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-4" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(_vm._s(_vm.trans("general.sort_by")))
                              ]),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.filterStockHistoryForm.sort_by,
                                      expression:
                                        "filterStockHistoryForm.sort_by"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: { name: "order" },
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.filterStockHistoryForm,
                                        "sort_by",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("option", { attrs: { value: "date" } }, [
                                    _vm._v(
                                      _vm._s(_vm.trans("stock_history.date"))
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "type" } }, [
                                    _vm._v(
                                      _vm._s(_vm.trans("stock_history.type"))
                                    )
                                  ])
                                ]
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-4" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(_vm._s(_vm.trans("general.order")))
                              ]),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.filterStockHistoryForm.order,
                                      expression: "filterStockHistoryForm.order"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: { name: "order" },
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.filterStockHistoryForm,
                                        "order",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("option", { attrs: { value: "asc" } }, [
                                    _vm._v(
                                      _vm._s(_vm.trans("general.ascending"))
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "desc" } }, [
                                    _vm._v(
                                      _vm._s(_vm.trans("general.descending"))
                                    )
                                  ])
                                ]
                              )
                            ])
                          ])
                        ])
                      ])
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "card" }, [
                _c(
                  "div",
                  { staticClass: "card-body" },
                  [
                    _c("h4", { staticClass: "card-title" }, [
                      _vm._v(_vm._s(_vm.trans("stock_history.history")))
                    ]),
                    _vm._v(" "),
                    _vm.stock_history_entries.total
                      ? _c("div", { staticClass: "table-responsive" }, [
                          _c("table", { staticClass: "table" }, [
                            _c("thead", [
                              _c("tr", [
                                _c("th", [
                                  _vm._v(
                                    _vm._s(_vm.trans("stock_history.date"))
                                  )
                                ]),
                                _vm._v(" "),
                                _c("th", [
                                  _vm._v(
                                    _vm._s(_vm.trans("stock_history.type"))
                                  )
                                ]),
                                _vm._v(" "),
                                _c("th", [
                                  _vm._v(
                                    _vm._s(_vm.trans("stock_history.number"))
                                  )
                                ])
                              ])
                            ]),
                            _vm._v(" "),
                            _c(
                              "tbody",
                              _vm._l(_vm.stock_history_entries.data, function(
                                stock_history
                              ) {
                                return _c("tr", [
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("moment")(stock_history.date)
                                      )
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("td", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.getStockHistoryType(
                                          stock_history.type
                                        )
                                      )
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("td", {
                                    domProps: {
                                      textContent: _vm._s(
                                        stock_history.number +
                                          (stock_history.inventory_status
                                            ? " (" +
                                              stock_history.inventory_status +
                                              ")"
                                            : "")
                                      )
                                    }
                                  })
                                ])
                              }),
                              0
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("pagination-record", {
                      attrs: {
                        "page-length": _vm.filterStockHistoryForm.page_length,
                        records: _vm.stock_history_entries
                      },
                      on: {
                        "update:pageLength": function($event) {
                          return _vm.$set(
                            _vm.filterStockHistoryForm,
                            "page_length",
                            $event
                          )
                        },
                        "update:page-length": function($event) {
                          return _vm.$set(
                            _vm.filterStockHistoryForm,
                            "page_length",
                            $event
                          )
                        },
                        updateRecords: _vm.getStockHistory
                      },
                      nativeOn: {
                        change: function($event) {
                          return _vm.getStockHistory($event)
                        }
                      }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }