<template>
    <div>
        <div class="row page-titles">
            <div class="col-md-12 col-12">
                <h3 class="text-themecolor m-b-0 m-t-0">{{trans('product.edit_stock_history')}}</h3>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><router-link to="/home">{{trans('general.home')}}</router-link></li>
                    <li class="breadcrumb-item active">{{trans('product.history')}}</li>
                </ol>
                <button class="btn btn-info btn-sm pull-right m-r-5" v-if="stock_history_entries.total && !showFilterPanel" @click="showFilterPanel = !showFilterPanel"><i class="fas fa-filter"></i> {{trans('general.filter')}}</button>
            </div>
        </div>
        <div class="container-fluid p-40">
        <div class="row">
            <product-sidebar menu="history" :id="id"></product-sidebar>
            <div class="col-sm-9">
                <transition name="fade">
                        <div class="card" v-if="showFilterPanel">
                            <div class="card-body">
                                    <button class="btn btn-info btn-sm pull-right" v-if="showFilterPanel" @click="showFilterPanel = !showFilterPanel">{{trans('general.hide')}}</button>
                                <h4 class="card-title">{{trans('general.filter')}}</h4>
                                <div class="row">
                                   <div class="col-4">
                                        <div class="form-group">
                                            <label for="">{{trans('stock_history.type')}}</label>
                                            <select name="order" class="form-control" v-model="filterStockHistoryForm.type">
                                                <option value="">Alle</option>
                                                <option value="i">Inventur</option>
                                                <option value="p">eingekauft</option>
                                                <option value="w">ausgebucht</option>
                                                <option value="s">verkauft</option>
                                                <option value="b">als verkauft markiert</option>
                                            </select>
                                        </div>
                                    </div>                                
                                   <div class="col-4">
                                        <div class="form-group">
                                            <label for="">{{trans('general.sort_by')}}</label>
                                            <select name="order" class="form-control" v-model="filterStockHistoryForm.sort_by">
                                                <option value="date">{{trans('stock_history.date')}}</option>
                                                <option value="type">{{trans('stock_history.type')}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label for="">{{trans('general.order')}}</label>
                                            <select name="order" class="form-control" v-model="filterStockHistoryForm.order">
                                                <option value="asc">{{trans('general.ascending')}}</option>
                                                <option value="desc">{{trans('general.descending')}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </transition>
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">{{trans('stock_history.history')}}</h4>   
                        <div class="table-responsive" v-if="stock_history_entries.total">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>{{trans('stock_history.date')}}</th>
                                        <th>{{trans('stock_history.type')}}</th>
                                        <th>{{trans('stock_history.number')}}</th>                                          
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="stock_history in stock_history_entries.data">
                                        <td>{{ stock_history.date | moment }}</td>
                                        <td v-text="getStockHistoryType(stock_history.type)"></td>
                                        <td v-text="stock_history.number + (stock_history.inventory_status?' ('+stock_history.inventory_status+')':'')"></td>                                    
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <pagination-record :page-length.sync="filterStockHistoryForm.page_length" :records="stock_history_entries" @updateRecords="getStockHistory" @change.native="getStockHistory"></pagination-record>                        
                    </div>
                </div>
            </div>
        </div>
            </div>
    </div>
</template>

<script>
    import productSidebar from './product-sidebar'
   
    export default {
        components : { productSidebar },
        data() {
            return {
                stock_history_entries: {
                    total: 0,
                    data: []
                },
                filterStockHistoryForm: {
                    page_length: helper.getConfig('page_length'),
                    sort_by: 'date',
                    order: 'desc',
                    type: ''
                },
                showFilterPanel: false,
                showCreatePanel: false,
                id:this.$route.params.id
            }
        },
        mounted(){
            if(!helper.hasPermission('access-product')){
                helper.notAccessibleMsg();
                this.$router.push('/home');
            }
            if(!helper.featureAvailable('product')){
                helper.featureNotAvailableMsg();
                this.$router.push('/home');
            }
             this.getStockHistory(); 
        },
        methods: {
            getStockHistory(page){
                if (typeof page !== 'number') {
                    page = 1;
                }
                let url = helper.getFilterURL(this.filterStockHistoryForm);
                axios.get('/api/product/stock-history?product_id=' + this.id + '&page=' + page + url)
                    .then(response => response.data)
                    .then(response => {
                            this.stock_history_entries = response;                            
                        }
                    )
                    .catch(error => {
                        helper.showDataErrorMsg(error);
                    });
            },
            getStockHistoryType(type){
                if(type == 'i'){
                    return 'Inventur';
                }else if(type == 'p'){
                    return 'eingekauft';
                }else if(type == 'w'){
                    return 'ausgebucht';
                }else if(type == 's'){
                    return 'verkauft';
                }else if(type == 'b'){
                    return 'als verkauft markiert';
                }else if(type == 'c'){
                    return 'c';
                }
            }
        },
        filters: {
          moment(date) {
            return helper.formatDate(date);
          }
        },
        watch: {
            filterStockHistoryForm: {
                handler(val){
                    this.getStockHistory();
                },
                deep: true
            }
        }
    }
</script>
