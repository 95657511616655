<template>
    <div class="col-3 col-sm-3">
        <div class="list-group">
            <router-link :to="`/products/${id}/edit`" :class="[menu === 'data' ? 'active' : '', 'list-group-item']" v-tooltip="trans('product.general_data')"><span class="hidden-sm-up"><i class="fas fa-user"></i></span>
                <span class="hidden-xs-down">
                    {{trans('product.general_data')}}
                </span>
            </router-link>
            <router-link :to="`/products/${id}/history`" :class="[menu === 'history' ? 'active' : '', 'list-group-item']" v-tooltip="trans('product.history')"><span class="hidden-sm-up"><i class="far fa-address-book"></i></span>
                <span class="hidden-xs-down">
                    {{trans('product.history')}}
                </span>
            </router-link>                     
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            menu: {
                default: ''
            },
            id: {
                required: true
            }
        },
        mounted(){
        },
        methods:{
            getAuthUser(name){
                return helper.getAuthUser(name);
            },
            getConfig(config){
                return helper.getConfig(config);
            }
        }
    }
</script>
