var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "page-titles p-3 border-bottom" }, [
      _c("h3", { staticClass: "text-themecolor" }, [
        _vm._v(_vm._s(_vm.trans("general.home")) + "\n            "),
        _c(
          "button",
          {
            staticClass: "btn btn-danger btn-sm pull-right",
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.logout($event)
              }
            }
          },
          [
            _c("i", { staticClass: "fas fa-power-off" }),
            _vm._v(" "),
            _c("span", { staticClass: "d-none d-sm-inline" }, [
              _vm._v(_vm._s(_vm.trans("auth.logout")))
            ])
          ]
        ),
        _vm._v(" "),
        _vm.hasRole("admin")
          ? _c(
              "button",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.trans("user.user_preference"),
                    expression: "trans('user.user_preference')"
                  }
                ],
                staticClass:
                  "btn btn-info btn-sm right-sidebar-toggle pull-right m-r-10"
              },
              [_c("i", { staticClass: "fas fa-cog" })]
            )
          : _vm._e()
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "container-fluid p-40" }, [
      _vm.hasRole("admin")
        ? _c("div", { staticClass: "row border-bottom" }, [
            _c("div", { staticClass: "col-12 col-sm-3" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body px-3 pt-3" }, [
                  _c("h4", { staticClass: "card-title" }, [
                    _vm._v(
                      _vm._s(
                        _vm.trans("dashboard.period_product_sales", {
                          period: _vm.trans("dashboard.total")
                        })
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-right" }, [
                    _c("h2", { staticClass: "font-light m-b-0" }, [
                      _c("i", {
                        staticClass: "fas fa-box fa-lg pull-right m-r-40"
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "pull-left" }, [
                        _vm._v(_vm._s(_vm.users))
                      ])
                    ])
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-sm-3" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body px-3 pt-3" }, [
                  _c("h4", { staticClass: "card-title" }, [
                    _vm._v(
                      _vm._s(
                        _vm.trans("dashboard.period_product_sales", {
                          period: _vm.trans("dashboard.today")
                        })
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-right" }, [
                    _c("h2", { staticClass: "font-light m-b-0" }, [
                      _c("i", {
                        staticClass: "fas fa-box fa-lg pull-right m-r-40"
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "pull-left" }, [
                        _vm._v(_vm._s(_vm.today_registered_users))
                      ])
                    ])
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-sm-3" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body px-3 pt-3" }, [
                  _c("h4", { staticClass: "card-title" }, [
                    _vm._v(
                      _vm._s(
                        _vm.trans("dashboard.period_product_sales", {
                          period: _vm.trans("dashboard.week")
                        })
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-right" }, [
                    _c("h2", { staticClass: "font-light m-b-0" }, [
                      _c("i", {
                        staticClass: "fas fa-box fa-lg pull-right m-r-40"
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "pull-left" }, [
                        _vm._v(_vm._s(_vm.weekly_registered_users))
                      ])
                    ])
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-sm-3" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body px-3 pt-3" }, [
                  _c("h4", { staticClass: "card-title" }, [
                    _vm._v(
                      _vm._s(
                        _vm.trans("dashboard.period_product_sales", {
                          period: _vm.trans("dashboard.month")
                        })
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-right" }, [
                    _c("h2", { staticClass: "font-light m-b-0" }, [
                      _c("i", {
                        staticClass: "fas fa-box fa-lg pull-right m-r-40"
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "pull-left" }, [
                        _vm._v(_vm._s(_vm.monthly_registered_users))
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "row mt-0 pt-5" }, [
        _c("div", { staticClass: "col-12 col-sm-4" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "px-3 pt-3" }, [
                _c("h4", { staticClass: "card-title" }, [
                  _vm._v(
                    "Abgelaufene Produkte\n                                "
                  ),
                  !_vm.expired_products_0.length
                    ? _c("span", { staticClass: "card-subtitle" }, [
                        _vm._v(_vm._s(_vm.trans("general.no_result_found")))
                      ])
                    : _vm._e()
                ])
              ]),
              _vm._v(" "),
              _vm.expired_products_0.length
                ? _c("div", { staticClass: "table-responsive" }, [
                    _c("table", { staticClass: "table table-hover" }, [
                      _c("thead", [
                        _c("tr", [
                          _c("th", [_vm._v(_vm._s(_vm.trans("product.name")))]),
                          _vm._v(" "),
                          _c("th", [
                            _vm._v(_vm._s(_vm.trans("product.expired_on")))
                          ]),
                          _vm._v(" "),
                          _c("th", { staticClass: "table-option" }, [
                            _vm._v(_vm._s(_vm.trans("general.action")))
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        _vm._l(_vm.expired_products_0, function(product) {
                          return product.number > 0
                            ? _c("tr", [
                                product.product
                                  ? _c("td", [
                                      _vm._v(_vm._s(product.product.name))
                                    ])
                                  : _c("td", [
                                      _vm._v(_vm._s(product.product_id))
                                    ]),
                                _vm._v(" "),
                                _c("td", {
                                  domProps: {
                                    textContent: _vm._s(product.expired_at)
                                  }
                                }),
                                _vm._v(" "),
                                _c("td", { staticClass: "table-option" }, [
                                  _c("div", { staticClass: "btn-group" }, [
                                    _c("input", {
                                      staticStyle: {
                                        width: "50px",
                                        "text-align": "center"
                                      },
                                      attrs: {
                                        type: "text",
                                        name: "",
                                        id: "sh" + product.id
                                      },
                                      domProps: { value: product.number }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-info btn-sm",
                                        on: {
                                          click: function($event) {
                                            return _vm.showDialog(product)
                                          }
                                        }
                                      },
                                      [_vm._v("Verkauft")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-danger btn-sm",
                                        on: {
                                          click: function($event) {
                                            return _vm.showDialog2(product)
                                          }
                                        }
                                      },
                                      [_vm._v("Ausbuchen")]
                                    )
                                  ])
                                ])
                              ])
                            : _vm._e()
                        }),
                        0
                      )
                    ])
                  ])
                : _vm._e()
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-sm-4" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "px-3 pt-3" }, [
                _c("h4", { staticClass: "card-title" }, [
                  _vm._v(
                    "Bald abgelaufene Produkte (aktuelle Woche)\n                                "
                  ),
                  !_vm.expired_products_7.length
                    ? _c("span", { staticClass: "card-subtitle" }, [
                        _vm._v(_vm._s(_vm.trans("general.no_result_found")))
                      ])
                    : _vm._e()
                ])
              ]),
              _vm._v(" "),
              _vm.expired_products_7.length
                ? _c("div", { staticClass: "table-responsive" }, [
                    _c("table", { staticClass: "table table-hover" }, [
                      _c("thead", [
                        _c("tr", [
                          _c("th", [_vm._v(_vm._s(_vm.trans("product.name")))]),
                          _vm._v(" "),
                          _c("th", [
                            _vm._v(_vm._s(_vm.trans("product.expired_at")))
                          ]),
                          _vm._v(" "),
                          _c("th", { staticClass: "table-option" }, [
                            _vm._v(_vm._s(_vm.trans("general.action")))
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        _vm._l(_vm.expired_products_7, function(product) {
                          return product.number > 0
                            ? _c("tr", [
                                product.product
                                  ? _c("td", [
                                      _vm._v(_vm._s(product.product.name))
                                    ])
                                  : _c("td", [
                                      _vm._v(_vm._s(product.product_id))
                                    ]),
                                _vm._v(" "),
                                _c("td", {
                                  domProps: {
                                    textContent: _vm._s(product.expired_at)
                                  }
                                }),
                                _vm._v(" "),
                                _c("td", { staticClass: "table-option" }, [
                                  _c("div", { staticClass: "btn-group" }, [
                                    _c("input", {
                                      staticStyle: {
                                        width: "50px",
                                        "text-align": "center"
                                      },
                                      attrs: {
                                        type: "text",
                                        name: "",
                                        id: "sh" + product.id
                                      },
                                      domProps: { value: product.number }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-info btn-sm",
                                        on: {
                                          click: function($event) {
                                            return _vm.showDialog(product)
                                          }
                                        }
                                      },
                                      [_vm._v("Verkauft")]
                                    )
                                  ])
                                ])
                              ])
                            : _vm._e()
                        }),
                        0
                      )
                    ])
                  ])
                : _vm._e()
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-sm-4" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "px-3 pt-3" }, [
                _c("h4", { staticClass: "card-title" }, [
                  _vm._v(
                    "Bald abgelaufene Produkte (nächste Woche)\n                                "
                  ),
                  !_vm.expired_products_14.length
                    ? _c("span", { staticClass: "card-subtitle" }, [
                        _vm._v(_vm._s(_vm.trans("general.no_result_found")))
                      ])
                    : _vm._e()
                ])
              ]),
              _vm._v(" "),
              _vm.expired_products_14.length
                ? _c("div", { staticClass: "table-responsive" }, [
                    _c("table", { staticClass: "table table-hover" }, [
                      _c("thead", [
                        _c("tr", [
                          _c("th", [_vm._v(_vm._s(_vm.trans("product.name")))]),
                          _vm._v(" "),
                          _c("th", [
                            _vm._v(_vm._s(_vm.trans("product.expired_at")))
                          ]),
                          _vm._v(" "),
                          _c("th", { staticClass: "table-option" }, [
                            _vm._v(_vm._s(_vm.trans("general.action")))
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        _vm._l(_vm.expired_products_14, function(product) {
                          return product.number > 0
                            ? _c("tr", [
                                product.product
                                  ? _c("td", [
                                      _vm._v(_vm._s(product.product.name))
                                    ])
                                  : _c("td", [
                                      _vm._v(_vm._s(product.product_id))
                                    ]),
                                _vm._v(" "),
                                _c("td", {
                                  domProps: {
                                    textContent: _vm._s(product.expired_at)
                                  }
                                }),
                                _vm._v(" "),
                                _c("td", { staticClass: "table-option" }, [
                                  _c("div", { staticClass: "btn-group" }, [
                                    _c("input", {
                                      staticStyle: {
                                        width: "50px",
                                        "text-align": "center"
                                      },
                                      attrs: {
                                        type: "text",
                                        name: "",
                                        id: "sh" + product.id
                                      },
                                      domProps: { value: product.number }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-info btn-sm",
                                        on: {
                                          click: function($event) {
                                            return _vm.showDialog(product)
                                          }
                                        }
                                      },
                                      [_vm._v("Verkauft")]
                                    )
                                  ])
                                ])
                              ])
                            : _vm._e()
                        }),
                        0
                      )
                    ])
                  ])
                : _vm._e()
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _vm.hasRole("admin")
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12 col-sm-3" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body px-3 pt-3" }, [
                  _c("h4", { staticClass: "card-title" }, [
                    _vm._v(
                      _vm._s(
                        _vm.trans("dashboard.period_registered_user", {
                          period: _vm.trans("dashboard.total")
                        })
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-right" }, [
                    _c("h2", { staticClass: "font-light m-b-0" }, [
                      _c("i", {
                        staticClass: "fas fa-users fa-lg pull-right m-r-40"
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "pull-left" }, [
                        _vm._v(_vm._s(_vm.users))
                      ])
                    ])
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-sm-3" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body px-3 pt-3" }, [
                  _c("h4", { staticClass: "card-title" }, [
                    _vm._v(
                      _vm._s(
                        _vm.trans("dashboard.period_registered_user", {
                          period: _vm.trans("dashboard.today")
                        })
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-right" }, [
                    _c("h2", { staticClass: "font-light m-b-0" }, [
                      _c("i", {
                        staticClass: "fas fa-users fa-lg pull-right m-r-40"
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "pull-left" }, [
                        _vm._v(_vm._s(_vm.today_registered_users))
                      ])
                    ])
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-sm-3" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body px-3 pt-3" }, [
                  _c("h4", { staticClass: "card-title" }, [
                    _vm._v(
                      _vm._s(
                        _vm.trans("dashboard.period_registered_user", {
                          period: _vm.trans("dashboard.week")
                        })
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-right" }, [
                    _c("h2", { staticClass: "font-light m-b-0" }, [
                      _c("i", {
                        staticClass: "fas fa-users fa-lg pull-right m-r-40"
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "pull-left" }, [
                        _vm._v(_vm._s(_vm.weekly_registered_users))
                      ])
                    ])
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-sm-3" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body px-3 pt-3" }, [
                  _c("h4", { staticClass: "card-title" }, [
                    _vm._v(
                      _vm._s(
                        _vm.trans("dashboard.period_registered_user", {
                          period: _vm.trans("dashboard.month")
                        })
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-right" }, [
                    _c("h2", { staticClass: "font-light m-b-0" }, [
                      _c("i", {
                        staticClass: "fas fa-users fa-lg pull-right m-r-40"
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "pull-left" }, [
                        _vm._v(_vm._s(_vm.monthly_registered_users))
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.hasRole("admin")
        ? _c("div", { staticClass: "row mt-0 border-top" }, [
            _c("div", { staticClass: "col-12 col-sm-6" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body" }, [
                  _c("div", { staticClass: "px-3 pt-3" }, [
                    _c("h4", { staticClass: "card-title" }, [
                      _vm._v(
                        _vm._s(_vm.trans("activity.activity_log")) +
                          "\n                                "
                      ),
                      !_vm.activity_logs.length
                        ? _c("span", { staticClass: "card-subtitle" }, [
                            _vm._v(_vm._s(_vm.trans("general.no_result_found")))
                          ])
                        : _vm._e()
                    ])
                  ]),
                  _vm._v(" "),
                  _vm.activity_logs.length
                    ? _c("div", { staticClass: "table-responsive" }, [
                        _c("table", { staticClass: "table table-hover" }, [
                          _c("thead", [
                            _c("tr", [
                              _vm.hasRole("admin")
                                ? _c("th", [
                                    _vm._v(_vm._s(_vm.trans("user.user")))
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("th", [
                                _vm._v(_vm._s(_vm.trans("activity.activity")))
                              ]),
                              _vm._v(" "),
                              _c("th", { staticClass: "table-option" }, [
                                _vm._v(_vm._s(_vm.trans("activity.date_time")))
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "tbody",
                            _vm._l(_vm.activity_logs, function(activity_log) {
                              return _c("tr", [
                                _vm.hasRole("admin")
                                  ? _c("td", {
                                      domProps: {
                                        textContent: _vm._s(
                                          activity_log.user.profile.first_name +
                                            " " +
                                            activity_log.user.profile.last_name
                                        )
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.trans(
                                        "activity." + activity_log.activity,
                                        {
                                          activity: _vm.trans(
                                            activity_log.module +
                                              "." +
                                              activity_log.module
                                          )
                                        }
                                      )
                                    )
                                  )
                                ]),
                                _vm._v(" "),
                                _c("td", { staticClass: "table-option" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("momentDateTime")(
                                        activity_log.created_at
                                      )
                                    )
                                  )
                                ])
                              ])
                            }),
                            0
                          )
                        ])
                      ])
                    : _vm._e()
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-sm-6" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body" }, [
                  _c("div", { staticClass: "px-3 pt-3" }, [
                    _c("h4", { staticClass: "card-title" }, [
                      _vm._v(
                        _vm._s(_vm.trans("todo.todo")) +
                          "\n                                "
                      ),
                      !_vm.todos.length
                        ? _c("span", { staticClass: "card-subtitle" }, [
                            _vm._v(_vm._s(_vm.trans("general.no_result_found")))
                          ])
                        : _vm._e()
                    ])
                  ]),
                  _vm._v(" "),
                  _vm.todos.length
                    ? _c("div", { staticClass: "table-responsive" }, [
                        _c("table", { staticClass: "table" }, [
                          _c("thead", [
                            _c("tr", [
                              _c("th", [
                                _vm._v(_vm._s(_vm.trans("todo.title")))
                              ]),
                              _vm._v(" "),
                              _c("th", [
                                _vm._v(_vm._s(_vm.trans("todo.status")))
                              ]),
                              _vm._v(" "),
                              _c("th", { staticClass: "table-option" }, [
                                _vm._v(_vm._s(_vm.trans("todo.date")))
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "tbody",
                            _vm._l(_vm.todos, function(todo) {
                              return _c("tr", [
                                _c("td", {
                                  domProps: { textContent: _vm._s(todo.title) }
                                }),
                                _vm._v(" "),
                                _c("td", {
                                  domProps: {
                                    innerHTML: _vm._s(_vm.getStatus(todo))
                                  }
                                }),
                                _vm._v(" "),
                                _c("td", { staticClass: "table-option" }, [
                                  _vm._v(_vm._s(_vm._f("moment")(todo.date)))
                                ])
                              ])
                            }),
                            0
                          )
                        ])
                      ])
                    : _vm._e()
                ])
              ])
            ])
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _vm.hasRole("admin")
      ? _c("div", { staticClass: "right-sidebar" }, [
          _c("div", { staticClass: "slimscrollright" }, [
            _c("div", { staticClass: "rpanel-title" }, [
              _vm._v(
                " \n                " +
                  _vm._s(_vm.trans("user.user_preference")) +
                  " \n                "
              ),
              _vm._m(0)
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "r-panel-body" }, [
              _c(
                "form",
                {
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.updatePreference($event)
                    },
                    keydown: function($event) {
                      return _vm.preferenceForm.errors.clear($event.target.name)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12 col-sm-6" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v(
                              _vm._s(_vm.trans("configuration.color_theme"))
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.preferenceForm.color_theme,
                                  expression: "preferenceForm.color_theme"
                                }
                              ],
                              staticClass: "custom-select col-12",
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.preferenceForm,
                                    "color_theme",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                }
                              }
                            },
                            _vm._l(_vm.color_themes, function(option) {
                              return _c(
                                "option",
                                { domProps: { value: option.value } },
                                [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(option.text) +
                                      "\n                                  "
                                  )
                                ]
                              )
                            }),
                            0
                          ),
                          _vm._v(" "),
                          _c("show-error", {
                            attrs: {
                              "form-name": _vm.preferenceForm,
                              "prop-name": "color_theme"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-12 col-sm-6" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v(_vm._s(_vm.trans("configuration.direction")))
                          ]),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.preferenceForm.direction,
                                  expression: "preferenceForm.direction"
                                }
                              ],
                              staticClass: "custom-select col-12",
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.preferenceForm,
                                    "direction",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                }
                              }
                            },
                            _vm._l(_vm.directions, function(option) {
                              return _c(
                                "option",
                                { domProps: { value: option.value } },
                                [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(option.text) +
                                      "\n                                  "
                                  )
                                ]
                              )
                            }),
                            0
                          ),
                          _vm._v(" "),
                          _c("show-error", {
                            attrs: {
                              "form-name": _vm.preferenceForm,
                              "prop-name": "direction"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-12 col-sm-6" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v(_vm._s(_vm.trans("configuration.sidebar")))
                          ]),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.preferenceForm.sidebar,
                                  expression: "preferenceForm.sidebar"
                                }
                              ],
                              staticClass: "custom-select col-12",
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.preferenceForm,
                                    "sidebar",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                }
                              }
                            },
                            _vm._l(_vm.sidebar, function(option) {
                              return _c(
                                "option",
                                { domProps: { value: option.value } },
                                [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(option.text) +
                                      "\n                                  "
                                  )
                                ]
                              )
                            }),
                            0
                          ),
                          _vm._v(" "),
                          _c("show-error", {
                            attrs: {
                              "form-name": _vm.preferenceForm,
                              "prop-name": "sidebar"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-12 col-sm-6" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v(_vm._s(_vm.trans("locale.locale")))
                          ]),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.preferenceForm.locale,
                                  expression: "preferenceForm.locale"
                                }
                              ],
                              staticClass: "custom-select col-12",
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.preferenceForm,
                                    "locale",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                }
                              }
                            },
                            _vm._l(_vm.locales, function(option) {
                              return _c(
                                "option",
                                { domProps: { value: option.value } },
                                [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(option.text) +
                                      "\n                                  "
                                  )
                                ]
                              )
                            }),
                            0
                          ),
                          _vm._v(" "),
                          _c("show-error", {
                            attrs: {
                              "form-name": _vm.preferenceForm,
                              "prop-name": "sidebar"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-info waves-effect waves-light pull-right m-t-10",
                      attrs: { type: "submit" }
                    },
                    [_vm._v(_vm._s(_vm.trans("general.save")))]
                  )
                ]
              )
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass:
          "btn btn-danger btn-sm right-sidebar-toggle pull-right m-r-10"
      },
      [_c("i", { staticClass: "fas fa-times" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }