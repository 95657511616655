var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-3 col-sm-3" }, [
    _c(
      "div",
      { staticClass: "list-group" },
      [
        _c(
          "router-link",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: _vm.trans("user.basic"),
                expression: "trans('user.basic')"
              }
            ],
            class: [_vm.menu === "basic" ? "active" : "", "list-group-item"],
            attrs: { to: "/user/" + _vm.id + "/basic" }
          },
          [
            _c("span", { staticClass: "hidden-sm-up" }, [
              _c("i", { staticClass: "fas fa-user" })
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "hidden-xs-down" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.trans("user.basic")) +
                  "\n            "
              )
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: _vm.trans("user.avatar"),
                expression: "trans('user.avatar')"
              }
            ],
            class: [_vm.menu === "avatar" ? "active" : "", "list-group-item"],
            attrs: { to: "/user/" + _vm.id + "/avatar" }
          },
          [
            _c("span", { staticClass: "hidden-sm-up" }, [
              _c("i", { staticClass: "far fa-address-book" })
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "hidden-xs-down" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.trans("user.avatar")) +
                  "\n            "
              )
            ])
          ]
        ),
        _vm._v(" "),
        _vm.id != _vm.getAuthUser("id")
          ? _c(
              "router-link",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.trans("user.reset_password"),
                    expression: "trans('user.reset_password')"
                  }
                ],
                class: [
                  _vm.menu === "password" ? "active" : "",
                  "list-group-item"
                ],
                attrs: { to: "/user/" + _vm.id + "/password" }
              },
              [
                _c("span", { staticClass: "hidden-sm-up" }, [
                  _c("i", { staticClass: "fas fa-key" })
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "hidden-xs-down" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.trans("user.reset_password")) +
                      "\n            "
                  )
                ])
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.getConfig("email_template")
          ? _c(
              "router-link",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.trans("user.email"),
                    expression: "trans('user.email')"
                  }
                ],
                class: [
                  _vm.menu === "email" ? "active" : "",
                  "list-group-item"
                ],
                attrs: { to: "/user/" + _vm.id + "/email" }
              },
              [
                _c("span", { staticClass: "hidden-sm-up" }, [
                  _c("i", { staticClass: "fas fa-envelope" })
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "hidden-xs-down" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.trans("user.email")) +
                      "\n            "
                  )
                ])
              ]
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }