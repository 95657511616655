var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row page-titles p-3 border-bottom" }, [
      _c("div", { staticClass: "col-md-12 col-12" }, [
        _vm.hasPermission("manage-cashregister")
          ? _c(
              "button",
              {
                staticClass: "btn btn-info pull-right",
                staticStyle: { "margin-top": "-5px" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.showLog($event)
                  }
                }
              },
              [_c("i", { staticClass: "fas fa-list" }), _vm._v(" Log")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.hasPermission("manage-cashregister")
          ? _c(
              "button",
              {
                staticClass: "btn btn-info pull-right",
                staticStyle: { "margin-right": "10px", "margin-top": "-5px" },
                attrs: { "data-toggle": "modal", "data-target": "#modStatus" }
              },
              [_c("i", { staticClass: "far fa-chart-bar" }), _vm._v(" Status")]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "span",
          {
            staticClass: "pull-right",
            staticStyle: { "margin-right": "20px" }
          },
          [_vm._v("Kassenbestand: " + _vm._s(_vm.amounts_cash + 600))]
        ),
        _vm._v(" "),
        _c("h3", { staticClass: "text-themecolor m-b-0 m-t-0" }, [
          _vm._v("Kasse")
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "container-fluid p-40" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12 col-lg-12 col-md-12" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-8 col-sm-8 col-md-8" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.ean_number,
                              expression: "ean_number"
                            }
                          ],
                          ref: "ean_number",
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            value: "",
                            name: "ean_number",
                            placeholder: "EAN Suche",
                            autocomplete: "off"
                          },
                          domProps: { value: _vm.ean_number },
                          on: {
                            input: [
                              function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.ean_number = $event.target.value
                              },
                              _vm.searchProduct,
                              _vm.resetProduct
                            ]
                          }
                        }),
                        _vm._v(" "),
                        _vm.clear_input
                          ? _c(
                              "span",
                              {
                                staticClass: "clearInput",
                                on: { click: _vm.clearInput }
                              },
                              [_vm._v("×")]
                            )
                          : _vm._e()
                      ])
                    ]),
                    _vm._v(" "),
                    _vm._m(0),
                    _vm._v(" "),
                    _vm._m(1)
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12 col-sm-12 col-md-12" }, [
                      _c("div", { staticClass: "table-responsive" }, [
                        _c("table", { staticClass: "table" }, [
                          _c("thead", [
                            _c(
                              "tr",
                              { staticStyle: { background: "#efefef" } },
                              [
                                _c("th", [
                                  _vm._v(
                                    _vm._s(_vm.trans("product.product_name"))
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  { attrs: { width: "80", align: "center" } },
                                  [_vm._v(_vm._s(_vm.trans("product.number")))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  { attrs: { width: "50", align: "right" } },
                                  [_vm._v(_vm._s(_vm.trans("product.price")))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  { attrs: { width: "50", align: "right" } },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.trans("product.discount"))
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c("th", { attrs: { width: "120" } }),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  { attrs: { width: "80", align: "right" } },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.trans("product.price_sum"))
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  { attrs: { width: "60", align: "right" } },
                                  [_vm._v(_vm._s(_vm.trans("product.tax")))]
                                ),
                                _vm._v(" "),
                                _c("th", {
                                  staticClass: "table-option",
                                  attrs: { width: "80" }
                                })
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _vm.purchasings.length
                            ? _c(
                                "tbody",
                                [
                                  _vm._l(_vm.purchasings, function(
                                    purchasing,
                                    key
                                  ) {
                                    return _c("tr", [
                                      _c("td", {
                                        domProps: {
                                          textContent: _vm._s(purchasing.name)
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("td", { attrs: { align: "center" } }, [
                                        _vm._v(_vm._s(purchasing.number))
                                      ]),
                                      _vm._v(" "),
                                      _c("td", { attrs: { align: "right" } }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.format_amount(
                                              purchasing.price_shop
                                            )
                                          )
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("td", { attrs: { align: "right" } }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.format_amount(
                                              purchasing.discount
                                            )
                                          )
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("td", { attrs: { width: "120" } }, [
                                        _c(
                                          "div",
                                          { staticClass: "btn-group" },
                                          [
                                            _c(
                                              "button",
                                              {
                                                staticClass: "btn btn-sm",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.addArticle(
                                                      purchasing
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "fas fa-plus"
                                                })
                                              ]
                                            ),
                                            _vm._v(" "),
                                            purchasing.number > 1
                                              ? _c(
                                                  "button",
                                                  {
                                                    staticClass: "btn btn-sm",
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.removeArticle(
                                                          purchasing
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-minus"
                                                    })
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("td", { attrs: { align: "right" } }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.format_amount(
                                              purchasing.price_sum
                                            )
                                          )
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("td", { attrs: { align: "right" } }, [
                                        _vm._v(_vm._s(purchasing.tax) + " %")
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        { staticClass: "table-option" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "btn-group" },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn btn-success btn-sm",
                                                  attrs: {
                                                    "data-toggle": "modal",
                                                    "data-target":
                                                      "#modDiscount"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.setDiscountProduct(
                                                        purchasing
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "fa fa-percent"
                                                  })
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "confirm",
                                                      rawName: "v-confirm",
                                                      value: {
                                                        ok: _vm.confirmDelete(
                                                          key,
                                                          purchasing
                                                        )
                                                      },
                                                      expression:
                                                        "{ok: confirmDelete(key,purchasing)}"
                                                    },
                                                    {
                                                      name: "tooltip",
                                                      rawName: "v-tooltip",
                                                      value: _vm.trans(
                                                        "purchasing.delete_purchasing"
                                                      ),
                                                      expression:
                                                        "trans('purchasing.delete_purchasing')"
                                                    }
                                                  ],
                                                  key: purchasing.id,
                                                  staticClass:
                                                    "btn btn-danger btn-sm"
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "fas fa-trash"
                                                  })
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ])
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "tr",
                                    { staticStyle: { background: "#efefef" } },
                                    [
                                      _c("td"),
                                      _vm._v(" "),
                                      _c("td"),
                                      _vm._v(" "),
                                      _c("td"),
                                      _vm._v(" "),
                                      _c("td"),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "font-weight": "bold"
                                          },
                                          attrs: { align: "right" }
                                        },
                                        [_vm._v("Total: ")]
                                      ),
                                      _vm._v(" "),
                                      _c("td", {
                                        staticClass: "cash-register-total",
                                        staticStyle: { "font-weight": "bold" },
                                        attrs: { align: "right" },
                                        domProps: {
                                          textContent: _vm._s(_vm.total_str)
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("td"),
                                      _vm._v(" "),
                                      _c("td")
                                    ]
                                  )
                                ],
                                2
                              )
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        !_vm.purchasings.length
                          ? _c(
                              "p",
                              { staticStyle: { "text-align": "center" } },
                              [
                                _vm._v(
                                  "Es wurden noch keine Produkte eingescannt."
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.show_success == true && !_vm.purchasings.length
                          ? _c(
                              "p",
                              {
                                staticStyle: {
                                  "text-align": "center",
                                  "max-width": "400px",
                                  padding: "20px",
                                  background: "#009f13",
                                  color: "#fff",
                                  "font-weight": "bold",
                                  margin: "40px auto"
                                }
                              },
                              [_vm._v("Die Zahlung war erfolgreich.")]
                            )
                          : _vm._e()
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _vm.purchasings.length && _vm.payment_buttons == true
                    ? _c(
                        "div",
                        {
                          staticClass: "row action_buttons",
                          staticStyle: { "padding-top": "40px" }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "col-4 col-sm-4 col-md-4" },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn",
                                  staticStyle: {
                                    "background-color": "#000000",
                                    color: "#fff",
                                    width: "100%",
                                    "line-height": "2",
                                    "font-weight": "bold",
                                    "font-size": "1.25em"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.setPaymentMethod("twint")
                                    }
                                  }
                                },
                                [_vm._v("Twint")]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-4 col-sm-4 col-md-4" },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn",
                                  staticStyle: {
                                    "background-color": "#009f13",
                                    color: "#fff",
                                    width: "100%",
                                    "line-height": "2",
                                    "font-weight": "bold",
                                    "font-size": "1.25em"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.setPaymentMethod("cash")
                                    }
                                  }
                                },
                                [_vm._v("Cash")]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-4 col-sm-4 col-md-4" },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn",
                                  staticStyle: {
                                    "background-color": "#00569f",
                                    color: "#fff",
                                    width: "100%",
                                    "line-height": "2",
                                    "font-weight": "bold",
                                    "font-size": "1.25em"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.setPaymentMethod("cc")
                                    }
                                  }
                                },
                                [_vm._v("Kartenzahlung")]
                              )
                            ]
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.purchasing_twint
                    ? _c(
                        "div",
                        {
                          staticClass: "row action_buttons",
                          staticStyle: { "padding-top": "40px" }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "col-4 col-sm-4 col-md-4" },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn",
                                  staticStyle: {
                                    "background-color": "#fe9900",
                                    color: "#fff",
                                    width: "100%",
                                    "line-height": "2",
                                    "font-weight": "bold",
                                    "font-size": "1.25em"
                                  },
                                  on: { click: _vm.resetPaymentMethod }
                                },
                                [_vm._v("Zurück")]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _vm._m(2),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-4 col-sm-4 col-md-4" },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn",
                                  staticStyle: {
                                    "background-color": "#000000",
                                    color: "#fff",
                                    width: "100%",
                                    "line-height": "2",
                                    "font-weight": "bold",
                                    "font-size": "1.25em"
                                  },
                                  on: { click: _vm.pay }
                                },
                                [_vm._v("Mit Twint bezahlt")]
                              )
                            ]
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.purchasing_cc
                    ? _c(
                        "div",
                        {
                          staticClass: "row action_buttons",
                          staticStyle: { "padding-top": "40px" }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "col-4 col-sm-4 col-md-4" },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn",
                                  staticStyle: {
                                    "background-color": "#fe9900",
                                    color: "#fff",
                                    width: "100%",
                                    "line-height": "2",
                                    "font-weight": "bold",
                                    "font-size": "1.25em"
                                  },
                                  on: { click: _vm.resetPaymentMethod }
                                },
                                [_vm._v("Zurück")]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _vm._m(3),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-4 col-sm-4 col-md-4" },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn",
                                  staticStyle: {
                                    "background-color": "#00569f",
                                    color: "#fff",
                                    width: "100%",
                                    "line-height": "2",
                                    "font-weight": "bold",
                                    "font-size": "1.25em"
                                  },
                                  on: { click: _vm.pay }
                                },
                                [_vm._v("Mit Kreditkarte bezahlt")]
                              )
                            ]
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.purchasing_cash
                    ? _c(
                        "div",
                        {
                          staticClass: "row action_buttons",
                          staticStyle: { "padding-top": "40px" }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "col-4 col-sm-4 col-md-4" },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn",
                                  staticStyle: {
                                    "background-color": "#fe9900",
                                    color: "#fff",
                                    width: "100%",
                                    "line-height": "2",
                                    "font-weight": "bold",
                                    "font-size": "1.25em"
                                  },
                                  on: { click: _vm.resetPaymentMethod }
                                },
                                [_vm._v("Zurück")]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-2 col-sm-2 col-md-2" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.cash_amount,
                                    expression: "cash_amount"
                                  }
                                ],
                                staticClass: "form-control",
                                staticStyle: {
                                  height: "100%",
                                  "font-size": "1.5em"
                                },
                                attrs: {
                                  type: "text",
                                  value: "",
                                  name: "cash_amount",
                                  placeholder: "Erhaltener Betrag"
                                },
                                domProps: { value: _vm.cash_amount },
                                on: {
                                  input: [
                                    function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.cash_amount = $event.target.value
                                    },
                                    _vm.setCashAmount
                                  ]
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "col-2 col-sm-2 col-md-2",
                              staticStyle: {
                                "line-height": "1.75em",
                                "font-weight": "bold",
                                "font-size": "2em"
                              }
                            },
                            [
                              _vm._v(
                                "\n                                    Rückgeld: "
                              ),
                              _c("span", {
                                domProps: {
                                  textContent: _vm._s(_vm.change_amount)
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-4 col-sm-4 col-md-4" },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn",
                                  staticStyle: {
                                    "background-color": "#00569f",
                                    color: "#fff",
                                    width: "100%",
                                    "line-height": "2",
                                    "font-weight": "bold",
                                    "font-size": "1.25em"
                                  },
                                  on: { click: _vm.pay }
                                },
                                [_vm._v("Bar bezahlt")]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-12 col-sm-12 col-md-12" },
                            [
                              _vm._v(
                                "\n                                      \n                                "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-2 col-sm-2 col-md-2" },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn",
                                  staticStyle: {
                                    "background-color": "#cccccc",
                                    color: "#fff",
                                    width: "100%",
                                    "line-height": "2",
                                    "font-weight": "bold",
                                    "font-size": "1.25em"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.setCashAmountFixed(5)
                                    }
                                  }
                                },
                                [_vm._v("CHF 5")]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-2 col-sm-2 col-md-2" },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn",
                                  staticStyle: {
                                    "background-color": "#cccccc",
                                    color: "#fff",
                                    width: "100%",
                                    "line-height": "2",
                                    "font-weight": "bold",
                                    "font-size": "1.25em"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.setCashAmountFixed(10)
                                    }
                                  }
                                },
                                [_vm._v("CHF 10")]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-2 col-sm-2 col-md-2" },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn",
                                  staticStyle: {
                                    "background-color": "#cccccc",
                                    color: "#fff",
                                    width: "100%",
                                    "line-height": "2",
                                    "font-weight": "bold",
                                    "font-size": "1.25em"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.setCashAmountFixed(20)
                                    }
                                  }
                                },
                                [_vm._v("CHF 20")]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-2 col-sm-2 col-md-2" },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn",
                                  staticStyle: {
                                    "background-color": "#cccccc",
                                    color: "#fff",
                                    width: "100%",
                                    "line-height": "2",
                                    "font-weight": "bold",
                                    "font-size": "1.25em"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.setCashAmountFixed(50)
                                    }
                                  }
                                },
                                [_vm._v("CHF 50")]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-2 col-sm-2 col-md-2" },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn",
                                  staticStyle: {
                                    "background-color": "#cccccc",
                                    color: "#fff",
                                    width: "100%",
                                    "line-height": "2",
                                    "font-weight": "bold",
                                    "font-size": "1.25em"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.setCashAmountFixed(100)
                                    }
                                  }
                                },
                                [_vm._v("CHF 100")]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-2 col-sm-2 col-md-2" },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn",
                                  staticStyle: {
                                    "background-color": "#cccccc",
                                    color: "#fff",
                                    width: "100%",
                                    "line-height": "2",
                                    "font-weight": "bold",
                                    "font-size": "1.25em"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.setCashAmountFixed(200)
                                    }
                                  }
                                },
                                [_vm._v("CHF 200")]
                              )
                            ]
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.payed
                    ? _c(
                        "div",
                        {
                          staticClass: "row action_buttons",
                          staticStyle: { "padding-top": "40px" }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "col-6 col-sm-6 col-md-6" },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn",
                                  staticStyle: {
                                    "background-color": "#009f13",
                                    color: "#fff",
                                    width: "100%",
                                    "line-height": "2",
                                    "font-weight": "bold",
                                    "font-size": "1.25em"
                                  },
                                  on: { click: _vm.resetPayment }
                                },
                                [_vm._v("Neuer Kunde")]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-6 col-sm-6 col-md-6" },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn",
                                  staticStyle: {
                                    "background-color": "#dbbf01",
                                    color: "#fff",
                                    width: "100%",
                                    "line-height": "2",
                                    "font-weight": "bold",
                                    "font-size": "1.25em"
                                  },
                                  on: { click: _vm.printBill }
                                },
                                [_vm._v("Quittung drucken")]
                              )
                            ]
                          )
                        ]
                      )
                    : _vm._e()
                ])
              ])
            ])
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal fade",
        staticStyle: { display: "none" },
        attrs: {
          id: "modStatus",
          tabindex: "-1",
          role: "dialog",
          "aria-labelledby": "myModalLabel",
          "aria-hidden": "true"
        }
      },
      [
        _c("div", { staticClass: "modal-dialog modal-lg" }, [
          _c("div", { staticClass: "modal-content" }, [
            _vm._m(4),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12 col-md-12" }, [
                  _c(
                    "table",
                    { staticClass: "table", attrs: { width: "100%" } },
                    [
                      _c("tr", [
                        _c("td", [_vm._v("Bar")]),
                        _vm._v(" "),
                        _c("td", { attrs: { align: "right" } }, [
                          _vm._v(
                            _vm._s(_vm.format_amount(_vm.amounts_cash)) +
                              " (" +
                              _vm._s(_vm.format_amount(_vm.amounts_cash_yd)) +
                              ")"
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", [_vm._v("Kreditkarte")]),
                        _vm._v(" "),
                        _c("td", { attrs: { align: "right" } }, [
                          _vm._v(
                            _vm._s(_vm.format_amount(_vm.amounts_cc)) +
                              " (" +
                              _vm._s(_vm.format_amount(_vm.amounts_cc_yd)) +
                              ")"
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", [_vm._v("Twint")]),
                        _vm._v(" "),
                        _c("td", { attrs: { align: "right" } }, [
                          _vm._v(
                            _vm._s(_vm.format_amount(_vm.amounts_twint)) +
                              " (" +
                              _vm._s(_vm.format_amount(_vm.amounts_twint_yd)) +
                              ")"
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", [_vm._v("Gesamt")]),
                        _vm._v(" "),
                        _c("td", { attrs: { align: "right" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.format_amount(
                                _vm.amounts_twint +
                                  _vm.amounts_cc +
                                  _vm.amounts_cash
                              )
                            ) +
                              " (" +
                              _vm._s(
                                _vm.format_amount(
                                  _vm.amounts_twint_yd +
                                    _vm.amounts_cc_yd +
                                    _vm.amounts_cash_yd
                                )
                              ) +
                              ")"
                          )
                        ])
                      ])
                    ]
                  )
                ])
              ])
            ])
          ])
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal fade",
        staticStyle: { display: "none" },
        attrs: {
          id: "modPrepaid",
          tabindex: "-1",
          role: "dialog",
          "aria-labelledby": "myModalLabel",
          "aria-hidden": "true"
        }
      },
      [
        _c("div", { staticClass: "modal-dialog modal-xl" }, [
          _c("div", { staticClass: "modal-content" }, [
            _vm._m(5),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body" }, [
              _c(
                "div",
                { staticClass: "row", staticStyle: { "padding-top": "20px" } },
                _vm._l(_vm.product_specials, function(product_special, key) {
                  return _c(
                    "div",
                    {
                      staticClass: "col-2",
                      staticStyle: { "padding-top": "10px" }
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-info",
                          staticStyle: { width: "100%" },
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.setProductSpecialNames(key)
                            }
                          }
                        },
                        [_c("span", [_vm._v(_vm._s(key))])]
                      )
                    ]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "row", staticStyle: { "padding-top": "20px" } },
                _vm._l(_vm.product_special_names, function(
                  product_special_name,
                  key
                ) {
                  return _c(
                    "div",
                    {
                      staticClass: "col-2",
                      staticStyle: { "padding-top": "10px" }
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary",
                          staticStyle: { width: "100%" },
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.setProductSpecialPrices(key)
                            }
                          }
                        },
                        [_c("span", [_vm._v(_vm._s(key))])]
                      )
                    ]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "row", staticStyle: { "padding-top": "20px" } },
                _vm._l(_vm.product_special_prices, function(
                  product_special_price,
                  key
                ) {
                  return _c(
                    "div",
                    {
                      staticClass: "col-2",
                      staticStyle: { "padding-top": "10px" }
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-success",
                          staticStyle: { width: "100%" },
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.setProductSpecialEAN(
                                product_special_price
                              )
                            }
                          }
                        },
                        [_c("span", [_vm._v(_vm._s(key))])]
                      )
                    ]
                  )
                }),
                0
              )
            ])
          ])
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal fade",
        staticStyle: { display: "none" },
        attrs: {
          id: "modProductNew",
          tabindex: "-1",
          role: "dialog",
          "aria-labelledby": "myModalLabel",
          "aria-hidden": "true"
        }
      },
      [
        _c("div", { staticClass: "modal-dialog modal-lg" }, [
          _c("div", { staticClass: "modal-content" }, [
            _vm._m(6),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12 col-md-12" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v(_vm._s(_vm.trans("product.name")))
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.productForm.name,
                            expression: "productForm.name"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", value: "", name: "name" },
                        domProps: { value: _vm.productForm.name },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.productForm,
                              "name",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("show-error", {
                        attrs: {
                          "form-name": _vm.productForm,
                          "prop-name": "name"
                        }
                      })
                    ],
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12 col-md-12" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v(_vm._s(_vm.trans("product.ean_number")))
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.productForm.ean_number,
                            expression: "productForm.ean_number"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", value: "", name: "name" },
                        domProps: { value: _vm.productForm.ean_number },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.productForm,
                              "ean_number",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("show-error", {
                        attrs: {
                          "form-name": _vm.productForm,
                          "prop-name": "ean_number"
                        }
                      })
                    ],
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12 col-md-12" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v(_vm._s(_vm.trans("product.price_shop")))
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.productForm.price_shop,
                            expression: "productForm.price_shop"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", value: "", name: "price_shop" },
                        domProps: { value: _vm.productForm.price_shop },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.productForm,
                              "price_shop",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("show-error", {
                        attrs: {
                          "form-name": _vm.productForm,
                          "prop-name": "price_shop"
                        }
                      })
                    ],
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12 col-md-12" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v(_vm._s(_vm.trans("product.tax")))
                      ]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.productForm.tax,
                              expression: "productForm.tax"
                            }
                          ],
                          staticClass: "form-control custom-select",
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.productForm,
                                "tax",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            }
                          }
                        },
                        [
                          !_vm.productForm.tax
                            ? _c("option", { attrs: { value: "" } }, [
                                _vm._v("Wählen Sie einen Prozentsatz aus")
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "2.5" } }, [
                            _vm._v("2,5 %")
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "7.7" } }, [
                            _vm._v("7,7 %")
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c("show-error", {
                        attrs: {
                          "form-name": _vm.productForm,
                          "prop-name": "tax"
                        }
                      })
                    ],
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-info waves-effect waves-light pull-right",
                      attrs: { type: "button" },
                      on: { click: _vm.addProduct }
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.trans("general.add")))])]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-danger waves-effect waves-light pull-right m-r-10",
                      attrs: {
                        type: "button",
                        "data-dismiss": "modal",
                        "aria-hidden": "true"
                      }
                    },
                    [_vm._v(_vm._s(_vm.trans("general.cancel")))]
                  )
                ])
              ])
            ])
          ])
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal fade",
        staticStyle: { display: "none" },
        attrs: {
          id: "modDiscount",
          tabindex: "-1",
          role: "dialog",
          "aria-labelledby": "myModalLabel",
          "aria-hidden": "true"
        }
      },
      [
        _c("div", { staticClass: "modal-dialog modal-lg" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c(
                "h4",
                { staticClass: "modal-title", attrs: { id: "myModalLabel" } },
                [
                  _vm._v(
                    "Rabatt hinzufügen (" +
                      _vm._s(_vm.discount_product.name) +
                      ")"
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: {
                    type: "button",
                    "data-dismiss": "modal",
                    "aria-hidden": "true"
                  }
                },
                [_vm._v("×")]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-6 col-md-6" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v(_vm._s(_vm.trans("product.amount")))
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.discountForm.amount,
                            expression: "discountForm.amount"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          maxlength: "6",
                          value: "",
                          name: "amount"
                        },
                        domProps: { value: _vm.discountForm.amount },
                        on: {
                          input: [
                            function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.discountForm,
                                "amount",
                                $event.target.value
                              )
                            },
                            _vm.calcDiscountAmount
                          ]
                        }
                      }),
                      _vm._v(" "),
                      _c("show-error", {
                        attrs: {
                          "form-name": _vm.discountForm,
                          "prop-name": "amount"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-6 col-md-6" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v(_vm._s(_vm.trans("product.percent")))
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.discountForm.percent,
                            expression: "discountForm.percent"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          maxlength: "2",
                          value: "",
                          name: "percent"
                        },
                        domProps: { value: _vm.discountForm.percent },
                        on: {
                          input: [
                            function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.discountForm,
                                "percent",
                                $event.target.value
                              )
                            },
                            _vm.calcDiscountPercent
                          ]
                        }
                      }),
                      _vm._v(" "),
                      _c("show-error", {
                        attrs: {
                          "form-name": _vm.discountForm,
                          "prop-name": "percent"
                        }
                      })
                    ],
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-6" }, [
                  _vm._v(
                    "Aktueller Einzelpreis: " +
                      _vm._s(
                        _vm.format_amount(
                          _vm.discount_product.price_shop -
                            _vm.discountForm.amount
                        )
                      )
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-6" }, [
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-info waves-effect waves-light pull-right",
                      attrs: { type: "button" },
                      on: { click: _vm.addDiscount }
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.trans("general.add")))])]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-danger waves-effect waves-light pull-right m-r-10",
                      attrs: {
                        type: "button",
                        "data-dismiss": "modal",
                        "aria-hidden": "true"
                      }
                    },
                    [_vm._v(_vm._s(_vm.trans("general.cancel")))]
                  )
                ])
              ])
            ])
          ])
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "printing_area", staticStyle: { display: "none" } },
      [
        _c("div", { staticClass: "headline" }, [_vm._v("Züri Shop GmbH")]),
        _vm._v(" "),
        _vm._m(7),
        _vm._v(" "),
        _c("div", { staticClass: "articles" }, [
          _c(
            "table",
            { staticClass: "table", attrs: { width: "100%" } },
            _vm._l(_vm.purchasings, function(purchasing, key) {
              return _c("tr", [
                _c("td", [_vm._v(_vm._s(purchasing.name))]),
                _vm._v(" "),
                _c("td", { attrs: { align: "right" } }, [
                  _vm._v(_vm._s(purchasing.price_shop))
                ])
              ])
            }),
            0
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "total" }, [
          _vm._v("\n        Total CHF 14.10\n    ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "rates" }, [
          _c("table", { staticClass: "table", attrs: { width: "100%" } }, [
            _vm._m(8),
            _vm._v(" "),
            _c("tr", [
              _c("td", [_vm._v("2,50%")]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(_vm.total_2_n))]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(_vm.total_2_t))]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(_vm.total_2))])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [_vm._v("7,70%")]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(_vm.total_7_n))]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(_vm.total_7_t))]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(_vm.total_7))])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [_vm._v("Summe")]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(_vm.total_n))]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(_vm.total_t))]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(_vm.total))])
            ])
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-2 col-sm-2 col-md-2" }, [
      _c("div", { staticClass: "form-group" }, [
        _c(
          "button",
          {
            staticClass: "btn",
            staticStyle: {
              "background-color": "#999",
              color: "#fff",
              width: "100%"
            },
            attrs: { "data-toggle": "modal", "data-target": "#modPrepaid" }
          },
          [_vm._v("Prepaid / CBD")]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-2 col-sm-2 col-md-2" }, [
      _c("div", { staticClass: "form-group" }, [
        _c(
          "button",
          {
            staticClass: "btn",
            staticStyle: {
              "background-color": "#999",
              color: "#fff",
              width: "100%"
            },
            attrs: { "data-toggle": "modal", "data-target": "#modProductNew" }
          },
          [_vm._v("Neues Produkt")]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-4 col-sm-4 col-md-4" }, [
      _c(
        "button",
        {
          staticClass: "btn",
          staticStyle: {
            "background-color": "#cc0001",
            color: "#fff",
            width: "100%",
            "line-height": "2",
            "font-weight": "bold",
            "font-size": "1.25em"
          }
        },
        [_vm._v("Stornieren")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-4 col-sm-4 col-md-4" }, [
      _c(
        "button",
        {
          staticClass: "btn",
          staticStyle: {
            "background-color": "#cc0001",
            color: "#fff",
            width: "100%",
            "line-height": "2",
            "font-weight": "bold",
            "font-size": "1.25em"
          }
        },
        [_vm._v("Stornieren")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h4", { staticClass: "modal-title", attrs: { id: "myModalLabel" } }, [
        _vm._v("Heutige Einnahmen (Vortag)")
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-hidden": "true"
          }
        },
        [_vm._v("×")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h4", { staticClass: "modal-title", attrs: { id: "myModalLabel" } }, [
        _vm._v("Prepaid / CBD")
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-hidden": "true"
          }
        },
        [_vm._v("×")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h4", { staticClass: "modal-title", attrs: { id: "myModalLabel" } }, [
        _vm._v("Neues Produkt hinzufügen")
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-hidden": "true"
          }
        },
        [_vm._v("×")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "infos" }, [
      _c("table", { staticClass: "table", attrs: { width: "100%" } }, [
        _c("tr", [
          _c("td", [_vm._v("Rechnungs-Nr.:")]),
          _vm._v(" "),
          _c("td", { attrs: { align: "right" } }, [_vm._v("123456")])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_vm._v("Datum:")]),
          _vm._v(" "),
          _c("td", { attrs: { align: "right" } }, [_vm._v("27.07.2020 14:59")])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_vm._v("Bedienung:")]),
          _vm._v(" "),
          _c("td", { attrs: { align: "right" } }, [_vm._v("Bedienung 1")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", [_vm._v("Rate")]),
      _vm._v(" "),
      _c("td", [_vm._v("Netto")]),
      _vm._v(" "),
      _c("td", [_vm._v("MwSt")]),
      _vm._v(" "),
      _c("td", [_vm._v("Brutto")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }