var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row page-titles p-3 border-bottom" }, [
      _c("div", { staticClass: "col-md-12 col-12" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-info pull-right",
            staticStyle: { "margin-top": "-5px" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.back($event)
              }
            }
          },
          [
            _c("i", { staticClass: "fal fa-cash-register" }),
            _vm._v(" zurück zur Kasse")
          ]
        ),
        _vm._v(" "),
        _c("h3", { staticClass: "text-themecolor m-b-0 m-t-0" }, [
          _vm._v("Kassenübersicht")
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "container-fluid p-40" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _vm.cashregister_bills.total
                  ? _c(
                      "div",
                      {
                        staticClass: "table-responsive table-cash-register-log"
                      },
                      [
                        _c("table", { staticClass: "table" }, [
                          _c("thead", [
                            _c("tr", [
                              _c("th", [
                                _vm._v(
                                  _vm._s(_vm.trans("cashregister_bill.number"))
                                )
                              ]),
                              _vm._v(" "),
                              _c("th", [
                                _vm._v(
                                  _vm._s(
                                    _vm.trans("cashregister_bill.timestamp")
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("th", [
                                _vm._v(
                                  _vm._s(
                                    _vm.trans("cashregister_bill.payment_type")
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("th", [
                                _vm._v(
                                  _vm._s(
                                    _vm.trans("cashregister_bill.articles")
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "th",
                                {
                                  staticStyle: { "text-align": "right" },
                                  attrs: { align: "right" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.trans("cashregister_bill.amount")
                                    )
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("th", { staticClass: "table-option" }, [
                                _vm._v(_vm._s(_vm.trans("general.action")))
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "tbody",
                            _vm._l(_vm.cashregister_bills.data, function(
                              cashregister_bill
                            ) {
                              return _c("tr", [
                                _c("td", {
                                  domProps: {
                                    textContent: _vm._s(
                                      cashregister_bill.number
                                    )
                                  }
                                }),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("moment")(
                                        cashregister_bill.created_at
                                      )
                                    )
                                  )
                                ]),
                                _vm._v(" "),
                                _c("td", {
                                  domProps: {
                                    textContent: _vm._s(
                                      cashregister_bill.payment_type
                                    )
                                  }
                                }),
                                _vm._v(" "),
                                _c("td", [
                                  _c(
                                    "ul",
                                    _vm._l(
                                      cashregister_bill.cashregister_entries,
                                      function(cashregister_bill_entry) {
                                        return _c("li", [
                                          _vm._v(
                                            "\n                                                    " +
                                              _vm._s(
                                                (cashregister_bill_entry.product_id
                                                  ? cashregister_bill_entry
                                                      .product.name
                                                  : cashregister_bill_entry.name) +
                                                  " (" +
                                                  cashregister_bill_entry.number +
                                                  " x " +
                                                  (cashregister_bill_entry.discount
                                                    ? _vm.format_amount(
                                                        cashregister_bill_entry.price -
                                                          cashregister_bill_entry.discount
                                                      ) +
                                                      " / " +
                                                      _vm.format_amount(
                                                        cashregister_bill_entry.price
                                                      )
                                                    : _vm.format_amount(
                                                        cashregister_bill_entry.price
                                                      )) +
                                                  ")"
                                              ) +
                                              "\n                                                "
                                          )
                                        ])
                                      }
                                    ),
                                    0
                                  )
                                ]),
                                _vm._v(" "),
                                _c("td", {
                                  attrs: { align: "right" },
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.format_amount(
                                        cashregister_bill.amount_total
                                      )
                                    )
                                  }
                                }),
                                _vm._v(" "),
                                _c("td", { staticClass: "table-option" }, [
                                  _c("div", { staticClass: "btn-group" }, [
                                    _c(
                                      "button",
                                      {
                                        directives: [
                                          {
                                            name: "confirm",
                                            rawName: "v-confirm",
                                            value: {
                                              ok: _vm.confirmPrint(
                                                cashregister_bill
                                              )
                                            },
                                            expression:
                                              "{ok: confirmPrint(cashregister_bill)}"
                                          },
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value: _vm.trans(
                                              "cashregister_bill.print_cashregister_bill"
                                            ),
                                            expression:
                                              "trans('cashregister_bill.print_cashregister_bill')"
                                          }
                                        ],
                                        staticClass: "btn btn-info btn-sm"
                                      },
                                      [_c("i", { staticClass: "fas fa-print" })]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        directives: [
                                          {
                                            name: "confirm",
                                            rawName: "v-confirm",
                                            value: {
                                              ok: _vm.confirmDelete(
                                                cashregister_bill
                                              )
                                            },
                                            expression:
                                              "{ok: confirmDelete(cashregister_bill)}"
                                          },
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value: _vm.trans(
                                              "cashregister_bill.delete_cashregister_bill"
                                            ),
                                            expression:
                                              "trans('cashregister_bill.delete_cashregister_bill')"
                                          }
                                        ],
                                        key: cashregister_bill.id,
                                        staticClass: "btn btn-danger btn-sm"
                                      },
                                      [_c("i", { staticClass: "fas fa-trash" })]
                                    )
                                  ])
                                ])
                              ])
                            }),
                            0
                          )
                        ])
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("pagination-record", {
                  attrs: {
                    "page-length": _vm.filterBillForm.page_length,
                    records: _vm.cashregister_bills
                  },
                  on: {
                    "update:pageLength": function($event) {
                      return _vm.$set(_vm.filterBillForm, "page_length", $event)
                    },
                    "update:page-length": function($event) {
                      return _vm.$set(_vm.filterBillForm, "page_length", $event)
                    },
                    updateRecords: _vm.getBills
                  },
                  nativeOn: {
                    change: function($event) {
                      return _vm.getBills($event)
                    }
                  }
                })
              ],
              1
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }