<template>
    <div>
        <div class="row page-titles p-3 border-bottom">
            <div class="col-md-12 col-12">
                <button class="btn btn-info pull-right" style="margin-top:-5px;" @click.prevent="back"><i class="fal fa-cash-register"></i> zurück zur Kasse</button>                                
                <h3 class="text-themecolor m-b-0 m-t-0">Kassendashboard</h3>                              
            </div>
        </div>
        <div class="container-fluid p-40">
        <div class="row">
             <div class="col-md-12 col-12">
             <transition name="fade">
                        <div class="card">
                            <div class="card-body">                                                                
                                <div class="row">
                                    <div class="col-md-1">
                                        <div class="form-group">
                                            <label for="">Von</label>
                                            <datepicker :language="de" :monday-first="true" v-model="filterForm.from" :bootstrapStyling="true"></datepicker>
                                            <show-error :form-name="filterForm" prop-name="from"></show-error>
                                        </div>
                                    </div>
                                    <div class="col-md-1">
                                        <div class="form-group">
                                            <label for="">Bis</label>
                                            <datepicker :language="de" :monday-first="true" v-model="filterForm.until" :bootstrapStyling="true"></datepicker>
                                            <show-error :form-name="filterForm" prop-name="until"></show-error>
                                        </div>
                                    </div>
                                    <div class="col-md-1">
                                        <div class="form-group">
                                            <label for="">Von</label>
                                            <select v-model="filterForm.fromTime" class="form-control">
                                                <option value="" selected>Alle</option>
                                                <option v-for="time in times_from" :value="time">{{ time }}</option>
                                            </select>
                                            <show-error :form-name="filterForm" prop-name="from"></show-error>
                                        </div>
                                    </div>
                                    <div class="col-md-1">
                                        <div class="form-group">
                                            <label for="">Bis</label>
                                            <select v-model="filterForm.untilTime" class="form-control">
                                                <option value="" selected>Alle</option>
                                                <option v-for="time in times_until" :value="time">{{ time }}</option>
                                            </select>
                                            <show-error :form-name="filterForm" prop-name="until"></show-error>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <label class="control-label">Mitarbeiter</label>
                                            <select class="form-control custom-select" v-model="filterForm.user_id">
                                                <option value="">Alle</option>
                                                <option value="" v-if="!users.length">Keine Mitarbeiter vorhanden</option>
                                                <option :value="user.id" v-for="user in users">{{ user.profile.first_name + ' ' + user.profile.last_name }}</option>
                                            </select>
                                            <show-error :form-name="filterForm" prop-name="user_id"></show-error>
                                        </div>
                                    </div> 
                                    <div class="col-3">
                                        <div class="form-group">
                                            <label for="">{{trans('product.ean_number')}}</label>
                                            <input class="form-control" name="ean_number" v-model="filterForm.ean_number">
                                            <show-error :form-name="filterForm" prop-name="ean_number"></show-error>
                                        </div>
                                    </div>
                                     <div class="col-2">
                                        <div class="form-group">
                                            <label for="">&nbsp;</label>
                                            <button type="button" style="width:100%;" class="btn btn-info" @click="getDashboard">Aktualisieren</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </transition>
             </div>
        </div>       
            <div class="row">
                <div class="col-12 col-sm-3">
                    <div class="card">
                        <div class="card-body px-3 pt-3">
                            <h4 class="card-title">Umsatz</h4>
                            <div class="text-right">
                                <h2 class="font-light m-b-0"><span class="pull-left">{{amount_income}}</span></h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-3">
                    <div class="card">
                        <div class="card-body px-3 pt-3">
                            <h4 class="card-title">Einkauf</h4>
                            <div class="text-right">
                                <h2 class="font-light m-b-0"><span class="pull-left">{{amount_costs}}</span></h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-3">
                    <div class="card">
                        <div class="card-body px-3 pt-3">
                            <h4 class="card-title">Gewinn</h4>
                            <div class="text-right">
                                <h2 class="font-light m-b-0"><span class="pull-left">{{amount_total}}</span></h2>
                            </div>
                        </div>
                    </div>
                </div>               
            </div>
            <div class="row">
                <div class="col-md-12 col-12">
                    <transition name="fade">
                        <div class="card">
                            <div class="card-body">                                                                
                                <h4 class="card-title">Nach Zeit</h4>
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Uhrzeit</th>
                                                <th>Betrag</th>
                                                <th>Produkte</th>                                                                                        
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(hour,key) in hours">
                                                <td>{{ key }}</td>
                                                <td>{{ format_amount(hour.amount) }}</td>
                                                <td>{{ hour.number }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
            </div> 
            <div class="row" v-if="categories">
                <div class="col-md-12 col-12">
                    <transition name="fade">
                        <div class="card">
                            <div class="card-body">                                                                
                                <h4 class="card-title">Nach Kategorie</h4>
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Betrag</th>
                                                <th>Produkte</th>                                                                                        
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(category,key) in categories">
                                                <td>{{ key }}</td>
                                                <td>{{ format_amount(category.amount) }} ({{ format_amount(category.amount/amount_income*100) }} %)</td>
                                                <td>{{ category.number }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>  
            <div class="row">
                <div class="col-md-12 col-12">
                    <transition name="fade">
                        <div class="card">
                            <div class="card-body">                                                                
                                <h4 class="card-title">Nach Zahlungsart</h4>
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Zahlungsart</th>
                                                <th>Betrag</th>
                                                <th>Produkte</th>                                                                                        
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(payment_type,key) in payment_types">
                                                <td>{{ payment_type.name }}</td>
                                                <td>{{ format_amount(payment_type.amount) }} ({{ format_amount(payment_type.amount/amount_income*100) }} %)</td>
                                                <td>{{ payment_type.number }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
            <div class="row" v-if="top_products.length">
                <div class="col-md-12 col-12">
                    <transition name="fade">
                        <div class="card">
                            <div class="card-body">                                                                
                                <h4 class="card-title">Top 10 Produkte</h4>
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Anzahl</th>                                                                                        
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(top_product,key) in top_products">
                                                <td>{{ top_product.product.name }}</td>                                                
                                                <td>{{ top_product.number }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>  
        </div> 
            
    </div>
</template>
<script>
    import datepicker from 'vuejs-datepicker'
    import {de} from 'vuejs-datepicker/dist/locale'
     
    export default {
        components: {datepicker},
        data() {
            return {               
                filterForm: new Form({
                    from: new Date(new Date().getFullYear(), new Date().getMonth(), 2).toISOString().slice(0,10),
                    until: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1).toISOString().slice(0,10),
                    user_id: '',
                    ean_number: '',
                    fromTime: '',
                    untilTime: ''
                }),
                times_from: [],
                times_until: [],
                users: '',
                page_cur: 1,
                amount_income: 0,
                amount_costs: 0,
                amount_total: 0,
                hours: [],
                categories: [],
                top_products: [],
                payment_types: [],
                de: de
            };
        },
        mounted(){
            if(!helper.hasPermission('manage-cashregister')){
                helper.notAccessibleMsg();
                this.$router.push('/home');
            }
            var i;
            for (i = 0; i < 24; i++) {
                if(i<10){
                    this.times_from.push('0'+i+':00');
                    this.times_from.push('0'+i+':30');
                    if(i>0){
                        this.times_until.push('0'+i+':00');    
                    }
                    this.times_until.push('0'+i+':30');                    
                }else{
                    this.times_from.push(i+':00');
                    this.times_from.push(i+':30');
                    this.times_until.push(i+':00');
                    this.times_until.push(i+':30');   
                    if(i == 23){
                        this.times_until.push('24:00');    
                    }
                    
                }              
            }
            
            this.getUsers();
            this.getDashboard();
        },
        methods: {
            back(){
                this.$router.push('/cash-register');
            },            
            format_amount(amount) {
                return helper.formatAmount(amount);
            },
            getUsers(){
                axios.get('/api/user')
                    .then(response => response.data)
                    .then(response => this.users = response.data)
                    .catch(error => {
                        helper.showDataErrorMsg(error);
                    })
            },  
            getDashboard(){             
                if (this.filterForm.from.getMonth) {
                    this.filterForm.from = this.filterForm.from.toISOString().slice(0,10)
                }
                if (this.filterForm.until.getMonth) {
                    this.filterForm.until = this.filterForm.until.toISOString().slice(0,10)
                }             
               //  let url = helper.getFilterURL(this.filterForm);
                
                let url = "from="+this.filterForm.from+"&until="+this.filterForm.until+"&from_time="+this.filterForm.fromTime+"&until_time="+this.filterForm.untilTime+"&user_id="+this.filterForm.user_id+"&ean_number="+this.filterForm.ean_number;
                
                axios.get('/api/cashregister-bill/dashboard?' + url)
                    .then(response => response.data)  
                    .then(response => {
                       // this.amount_income = format_amount(response.total);
                        this.amount_income = this.format_amount(response.total);
                        this.hours = response.hours;
                        this.categories = response.categories;
                        this.top_products = response.top_products;
                        this.payment_types = response.payment_types;
                    })
                    .catch(error => {                        
                        helper.showDataErrorMsg(error);
                    })
            },  
        },
        filters: {
          moment(date) {
            return helper.formatDateTime(date);
          }
        },
    }
</script>
