var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row page-titles p-3 border-bottom" }, [
      _c("div", { staticClass: "col-md-12 col-12" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-info pull-right",
            staticStyle: { "margin-top": "-5px" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.back($event)
              }
            }
          },
          [
            _c("i", { staticClass: "fal fa-cash-register" }),
            _vm._v(" zurück zur Kasse")
          ]
        ),
        _vm._v(" "),
        _c("h3", { staticClass: "text-themecolor m-b-0 m-t-0" }, [
          _vm._v("Kassendashboard")
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "container-fluid p-40" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-12 col-12" },
          [
            _c("transition", { attrs: { name: "fade" } }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-1" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { attrs: { for: "" } }, [_vm._v("Von")]),
                          _vm._v(" "),
                          _c("datepicker", {
                            attrs: {
                              language: _vm.de,
                              "monday-first": true,
                              bootstrapStyling: true
                            },
                            model: {
                              value: _vm.filterForm.from,
                              callback: function($$v) {
                                _vm.$set(_vm.filterForm, "from", $$v)
                              },
                              expression: "filterForm.from"
                            }
                          }),
                          _vm._v(" "),
                          _c("show-error", {
                            attrs: {
                              "form-name": _vm.filterForm,
                              "prop-name": "from"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-1" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { attrs: { for: "" } }, [_vm._v("Bis")]),
                          _vm._v(" "),
                          _c("datepicker", {
                            attrs: {
                              language: _vm.de,
                              "monday-first": true,
                              bootstrapStyling: true
                            },
                            model: {
                              value: _vm.filterForm.until,
                              callback: function($$v) {
                                _vm.$set(_vm.filterForm, "until", $$v)
                              },
                              expression: "filterForm.until"
                            }
                          }),
                          _vm._v(" "),
                          _c("show-error", {
                            attrs: {
                              "form-name": _vm.filterForm,
                              "prop-name": "until"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-1" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { attrs: { for: "" } }, [_vm._v("Von")]),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.filterForm.fromTime,
                                  expression: "filterForm.fromTime"
                                }
                              ],
                              staticClass: "form-control",
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.filterForm,
                                    "fromTime",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                }
                              }
                            },
                            [
                              _c(
                                "option",
                                { attrs: { value: "", selected: "" } },
                                [_vm._v("Alle")]
                              ),
                              _vm._v(" "),
                              _vm._l(_vm.times_from, function(time) {
                                return _c(
                                  "option",
                                  { domProps: { value: time } },
                                  [_vm._v(_vm._s(time))]
                                )
                              })
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c("show-error", {
                            attrs: {
                              "form-name": _vm.filterForm,
                              "prop-name": "from"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-1" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { attrs: { for: "" } }, [_vm._v("Bis")]),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.filterForm.untilTime,
                                  expression: "filterForm.untilTime"
                                }
                              ],
                              staticClass: "form-control",
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.filterForm,
                                    "untilTime",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                }
                              }
                            },
                            [
                              _c(
                                "option",
                                { attrs: { value: "", selected: "" } },
                                [_vm._v("Alle")]
                              ),
                              _vm._v(" "),
                              _vm._l(_vm.times_until, function(time) {
                                return _c(
                                  "option",
                                  { domProps: { value: time } },
                                  [_vm._v(_vm._s(time))]
                                )
                              })
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c("show-error", {
                            attrs: {
                              "form-name": _vm.filterForm,
                              "prop-name": "until"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-3" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { staticClass: "control-label" }, [
                            _vm._v("Mitarbeiter")
                          ]),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.filterForm.user_id,
                                  expression: "filterForm.user_id"
                                }
                              ],
                              staticClass: "form-control custom-select",
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.filterForm,
                                    "user_id",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                }
                              }
                            },
                            [
                              _c("option", { attrs: { value: "" } }, [
                                _vm._v("Alle")
                              ]),
                              _vm._v(" "),
                              !_vm.users.length
                                ? _c("option", { attrs: { value: "" } }, [
                                    _vm._v("Keine Mitarbeiter vorhanden")
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm._l(_vm.users, function(user) {
                                return _c(
                                  "option",
                                  { domProps: { value: user.id } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        user.profile.first_name +
                                          " " +
                                          user.profile.last_name
                                      )
                                    )
                                  ]
                                )
                              })
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c("show-error", {
                            attrs: {
                              "form-name": _vm.filterForm,
                              "prop-name": "user_id"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-3" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v(_vm._s(_vm.trans("product.ean_number")))
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.filterForm.ean_number,
                                expression: "filterForm.ean_number"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { name: "ean_number" },
                            domProps: { value: _vm.filterForm.ean_number },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.filterForm,
                                  "ean_number",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("show-error", {
                            attrs: {
                              "form-name": _vm.filterForm,
                              "prop-name": "ean_number"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-2" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "" } }, [_vm._v(" ")]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-info",
                            staticStyle: { width: "100%" },
                            attrs: { type: "button" },
                            on: { click: _vm.getDashboard }
                          },
                          [_vm._v("Aktualisieren")]
                        )
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12 col-sm-3" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body px-3 pt-3" }, [
              _c("h4", { staticClass: "card-title" }, [_vm._v("Umsatz")]),
              _vm._v(" "),
              _c("div", { staticClass: "text-right" }, [
                _c("h2", { staticClass: "font-light m-b-0" }, [
                  _c("span", { staticClass: "pull-left" }, [
                    _vm._v(_vm._s(_vm.amount_income))
                  ])
                ])
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-sm-3" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body px-3 pt-3" }, [
              _c("h4", { staticClass: "card-title" }, [_vm._v("Einkauf")]),
              _vm._v(" "),
              _c("div", { staticClass: "text-right" }, [
                _c("h2", { staticClass: "font-light m-b-0" }, [
                  _c("span", { staticClass: "pull-left" }, [
                    _vm._v(_vm._s(_vm.amount_costs))
                  ])
                ])
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-sm-3" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body px-3 pt-3" }, [
              _c("h4", { staticClass: "card-title" }, [_vm._v("Gewinn")]),
              _vm._v(" "),
              _c("div", { staticClass: "text-right" }, [
                _c("h2", { staticClass: "font-light m-b-0" }, [
                  _c("span", { staticClass: "pull-left" }, [
                    _vm._v(_vm._s(_vm.amount_total))
                  ])
                ])
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-12 col-12" },
          [
            _c("transition", { attrs: { name: "fade" } }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body" }, [
                  _c("h4", { staticClass: "card-title" }, [
                    _vm._v("Nach Zeit")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "table-responsive" }, [
                    _c("table", { staticClass: "table" }, [
                      _c("thead", [
                        _c("tr", [
                          _c("th", [_vm._v("Uhrzeit")]),
                          _vm._v(" "),
                          _c("th", [_vm._v("Betrag")]),
                          _vm._v(" "),
                          _c("th", [_vm._v("Produkte")])
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        _vm._l(_vm.hours, function(hour, key) {
                          return _c("tr", [
                            _c("td", [_vm._v(_vm._s(key))]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(_vm._s(_vm.format_amount(hour.amount)))
                            ]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(hour.number))])
                          ])
                        }),
                        0
                      )
                    ])
                  ])
                ])
              ])
            ])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm.categories
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-12 col-12" },
              [
                _c("transition", { attrs: { name: "fade" } }, [
                  _c("div", { staticClass: "card" }, [
                    _c("div", { staticClass: "card-body" }, [
                      _c("h4", { staticClass: "card-title" }, [
                        _vm._v("Nach Kategorie")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "table-responsive" }, [
                        _c("table", { staticClass: "table" }, [
                          _c("thead", [
                            _c("tr", [
                              _c("th", [_vm._v("Name")]),
                              _vm._v(" "),
                              _c("th", [_vm._v("Betrag")]),
                              _vm._v(" "),
                              _c("th", [_vm._v("Produkte")])
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "tbody",
                            _vm._l(_vm.categories, function(category, key) {
                              return _c("tr", [
                                _c("td", [_vm._v(_vm._s(key))]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(_vm.format_amount(category.amount)) +
                                      " (" +
                                      _vm._s(
                                        _vm.format_amount(
                                          (category.amount /
                                            _vm.amount_income) *
                                            100
                                        )
                                      ) +
                                      " %)"
                                  )
                                ]),
                                _vm._v(" "),
                                _c("td", [_vm._v(_vm._s(category.number))])
                              ])
                            }),
                            0
                          )
                        ])
                      ])
                    ])
                  ])
                ])
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-12 col-12" },
          [
            _c("transition", { attrs: { name: "fade" } }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body" }, [
                  _c("h4", { staticClass: "card-title" }, [
                    _vm._v("Nach Zahlungsart")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "table-responsive" }, [
                    _c("table", { staticClass: "table" }, [
                      _c("thead", [
                        _c("tr", [
                          _c("th", [_vm._v("Zahlungsart")]),
                          _vm._v(" "),
                          _c("th", [_vm._v("Betrag")]),
                          _vm._v(" "),
                          _c("th", [_vm._v("Produkte")])
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        _vm._l(_vm.payment_types, function(payment_type, key) {
                          return _c("tr", [
                            _c("td", [_vm._v(_vm._s(payment_type.name))]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(_vm.format_amount(payment_type.amount)) +
                                  " (" +
                                  _vm._s(
                                    _vm.format_amount(
                                      (payment_type.amount /
                                        _vm.amount_income) *
                                        100
                                    )
                                  ) +
                                  " %)"
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(payment_type.number))])
                          ])
                        }),
                        0
                      )
                    ])
                  ])
                ])
              ])
            ])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm.top_products.length
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-12 col-12" },
              [
                _c("transition", { attrs: { name: "fade" } }, [
                  _c("div", { staticClass: "card" }, [
                    _c("div", { staticClass: "card-body" }, [
                      _c("h4", { staticClass: "card-title" }, [
                        _vm._v("Top 10 Produkte")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "table-responsive" }, [
                        _c("table", { staticClass: "table" }, [
                          _c("thead", [
                            _c("tr", [
                              _c("th", [_vm._v("Name")]),
                              _vm._v(" "),
                              _c("th", [_vm._v("Anzahl")])
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "tbody",
                            _vm._l(_vm.top_products, function(
                              top_product,
                              key
                            ) {
                              return _c("tr", [
                                _c("td", [
                                  _vm._v(_vm._s(top_product.product.name))
                                ]),
                                _vm._v(" "),
                                _c("td", [_vm._v(_vm._s(top_product.number))])
                              ])
                            }),
                            0
                          )
                        ])
                      ])
                    ])
                  ])
                ])
              ],
              1
            )
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }