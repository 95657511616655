var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "page-titles p-3 border-bottom" }, [
      _c("h3", { staticClass: "text-themecolor" }, [
        _vm._v(_vm._s(_vm.trans("user.user")) + " \n            "),
        _vm.user.profile
          ? _c("span", { staticClass: "card-subtitle" }, [
              _vm._v(
                _vm._s(
                  _vm.user.profile.first_name + " " + _vm.user.profile.last_name
                ) +
                  " (" +
                  _vm._s(_vm.user.email) +
                  ")"
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-info btn-sm pull-right",
            on: {
              click: function($event) {
                return _vm.$router.push("/user")
              }
            }
          },
          [
            _c("i", { staticClass: "fas fa-list" }),
            _vm._v(" "),
            _c("span", { staticClass: "d-none d-sm-inline" }, [
              _vm._v(_vm._s(_vm.trans("user.user_list")))
            ])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "container-fluid p-0" }, [
      _c(
        "div",
        { staticClass: "row" },
        [
          _c("div", { staticClass: "col-12 col-sm-8" }, [
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-body p-4" }, [
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("user-sidebar", {
                      attrs: { menu: "basic", id: _vm.id }
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-9 col-sm-9" }, [
                      _c("h4", { staticClass: "card-title" }, [
                        _vm._v(_vm._s(_vm.trans("user.basic")))
                      ]),
                      _vm._v(" "),
                      _c(
                        "form",
                        {
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.submit($event)
                            },
                            keydown: function($event) {
                              return _vm.userForm.errors.clear(
                                $event.target.name
                              )
                            }
                          }
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-12 col-sm-12" }, [
                              _c(
                                "div",
                                { staticClass: "row" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-6" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.trans("user.first_name")
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.userForm.first_name,
                                                expression:
                                                  "userForm.first_name"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              type: "text",
                                              value: "",
                                              name: "first_name",
                                              placeholder: _vm.trans(
                                                "user.first_name"
                                              )
                                            },
                                            domProps: {
                                              value: _vm.userForm.first_name
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.userForm,
                                                  "first_name",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.userForm,
                                              "prop-name": "first_name"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-6" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.trans("user.last_name")
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.userForm.last_name,
                                                expression: "userForm.last_name"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              type: "text",
                                              value: "",
                                              name: "last_name",
                                              placeholder: _vm.trans(
                                                "user.last_name"
                                              )
                                            },
                                            domProps: {
                                              value: _vm.userForm.last_name
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.userForm,
                                                  "last_name",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.userForm,
                                              "prop-name": "last_name"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-6" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(_vm.trans("user.email"))
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.userForm.email,
                                                expression: "userForm.email"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              type: "text",
                                              value: "",
                                              name: "email",
                                              placeholder: _vm.trans(
                                                "user.email"
                                              ),
                                              readonly: ""
                                            },
                                            domProps: {
                                              value: _vm.userForm.email
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.userForm,
                                                  "email",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.userForm,
                                              "prop-name": "email"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-6" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(_vm.trans("user.gender"))
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _vm._l(_vm.genders, function(gender) {
                                            return _c(
                                              "div",
                                              {
                                                staticClass: "radio radio-info"
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.userForm.gender,
                                                      expression:
                                                        "userForm.gender"
                                                    }
                                                  ],
                                                  attrs: {
                                                    type: "radio",
                                                    id: gender.id,
                                                    name: "gender"
                                                  },
                                                  domProps: {
                                                    value: gender.id,
                                                    checked:
                                                      _vm.userForm.gender ==
                                                      gender.id,
                                                    checked: _vm._q(
                                                      _vm.userForm.gender,
                                                      gender.id
                                                    )
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      return _vm.$set(
                                                        _vm.userForm,
                                                        "gender",
                                                        gender.id
                                                      )
                                                    }
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "label",
                                                  { attrs: { for: gender.id } },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.trans(
                                                            "list." + gender.id
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          }),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.userForm,
                                              "prop-name": "gender"
                                            }
                                          })
                                        ],
                                        2
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  !_vm.userHasRole(_vm.user, "admin")
                                    ? [
                                        _c(
                                          "div",
                                          { staticClass: "col-12 col-sm-6" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "form-group" },
                                              [
                                                _c(
                                                  "label",
                                                  { attrs: { for: "" } },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.trans("role.role")
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("v-select", {
                                                  attrs: {
                                                    label: "name",
                                                    "track-by": "id",
                                                    name: "role_id",
                                                    id: "role_id",
                                                    options: _vm.roles,
                                                    placeholder: _vm.trans(
                                                      "role.select_role"
                                                    ),
                                                    multiple: true,
                                                    "close-on-select": false,
                                                    "clear-on-select": false,
                                                    "hide-selected": true,
                                                    selected: _vm.selected_roles
                                                  },
                                                  on: {
                                                    select: _vm.onRoleSelect,
                                                    remove: _vm.onRoleRemove
                                                  },
                                                  model: {
                                                    value: _vm.selected_roles,
                                                    callback: function($$v) {
                                                      _vm.selected_roles = $$v
                                                    },
                                                    expression: "selected_roles"
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c("show-error", {
                                                  attrs: {
                                                    "form-name": _vm.userForm,
                                                    "prop-name": "role_id"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ]
                                    : _vm._e()
                                ],
                                2
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-sm btn-info pull-right",
                              attrs: { type: "submit" }
                            },
                            [_vm._v(_vm._s(_vm.trans("general.save")))]
                          )
                        ]
                      )
                    ])
                  ],
                  1
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("user-summary", { attrs: { user: _vm.user } })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }