var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "page-titles p-3 border-bottom" }, [
      _c("h3", { staticClass: "text-themecolor" }, [
        _vm._v(_vm._s(_vm.trans("product.product")) + "\n            "),
        _vm.products
          ? _c("span", { staticClass: "card-subtitle" }, [
              _vm._v(
                _vm._s(
                  _vm.trans("general.total_result_found", {
                    count: _vm.products.total
                  })
                )
              )
            ])
          : _c("span", { staticClass: "card-subtitle" }, [
              _vm._v(_vm._s(_vm.trans("general.no_result_found")))
            ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-info btn-sm pull-right",
            on: {
              click: function($event) {
                return _vm.$router.push("/home")
              }
            }
          },
          [
            _c("i", { staticClass: "fas fa-home" }),
            _vm._v(" "),
            _c("span", { staticClass: "d-none d-sm-inline" }, [
              _vm._v(_vm._s(_vm.trans("general.home")))
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-info btn-sm pull-right m-r-10",
            on: {
              click: function($event) {
                _vm.showCreatePanel = !_vm.showCreatePanel
              }
            }
          },
          [
            _c("i", { staticClass: "fas fa-plus" }),
            _vm._v(" "),
            _c("span", { staticClass: "d-none d-sm-inline" }, [
              _vm._v(_vm._s(_vm.trans("product.add_new_product")))
            ])
          ]
        ),
        _vm._v(" "),
        _vm.products.total && !_vm.showFilterPanel
          ? _c(
              "button",
              {
                staticClass: "btn btn-info btn-sm pull-right m-r-5",
                on: {
                  click: function($event) {
                    _vm.showFilterPanel = !_vm.showFilterPanel
                  }
                }
              },
              [
                _c("i", { staticClass: "fas fa-filter" }),
                _vm._v(" " + _vm._s(_vm.trans("general.filter")))
              ]
            )
          : _vm._e()
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "container-fluid p-40" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("transition", { attrs: { name: "fade" } }, [
              _vm.showFilterPanel
                ? _c("div", { staticClass: "card" }, [
                    _c("div", { staticClass: "card-body" }, [
                      _vm.showFilterPanel
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-info btn-sm pull-right",
                              on: {
                                click: function($event) {
                                  _vm.showFilterPanel = !_vm.showFilterPanel
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.trans("general.hide")))]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("h4", { staticClass: "card-title" }, [
                        _vm._v(_vm._s(_vm.trans("general.filter")))
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-6" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.trans("product.ean_number")))
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.filterProductForm.ean_number,
                                  expression: "filterProductForm.ean_number"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: { name: "ean_number" },
                              domProps: {
                                value: _vm.filterProductForm.ean_number
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.filterProductForm,
                                    "ean_number",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-6" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.trans("product.name")))
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.filterProductForm.name,
                                  expression: "filterProductForm.name"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: { name: "name" },
                              domProps: { value: _vm.filterProductForm.name },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.filterProductForm,
                                    "name",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ])
                        ])
                      ])
                    ])
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _vm.hasPermission("access-product")
              ? _c("transition", { attrs: { name: "fade" } }, [
                  _vm.showCreatePanel
                    ? _c("div", { staticClass: "card" }, [
                        _c(
                          "div",
                          { staticClass: "card-body" },
                          [
                            _vm.showCreatePanel
                              ? _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-info btn-sm pull-right",
                                    on: {
                                      click: function($event) {
                                        _vm.showCreatePanel = !_vm.showCreatePanel
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.trans("general.hide")))]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("h4", { staticClass: "card-title" }, [
                              _vm._v(
                                _vm._s(_vm.trans("product.add_new_product"))
                              )
                            ]),
                            _vm._v(" "),
                            _c("product-form", {
                              on: { completed: _vm.getProducts }
                            })
                          ],
                          1
                        )
                      ])
                    : _vm._e()
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "card" }, [
              _c(
                "div",
                { staticClass: "card-body" },
                [
                  _vm.products.total
                    ? _c("div", { staticClass: "table-responsive" }, [
                        _c("table", { staticClass: "table" }, [
                          _c("thead", [
                            _c("tr", [
                              _c("th", [
                                _vm._v(_vm._s(_vm.trans("product.ean_number")))
                              ]),
                              _vm._v(" "),
                              _c("th", [
                                _vm._v(_vm._s(_vm.trans("product.name")))
                              ]),
                              _vm._v(" "),
                              _c("th", [
                                _vm._v(_vm._s(_vm.trans("product.supplier")))
                              ]),
                              _vm._v(" "),
                              _c("th", [
                                _vm._v(_vm._s(_vm.trans("product.stock_cur")))
                              ]),
                              _vm._v(" "),
                              _c("th", { staticClass: "table-option" }, [
                                _vm._v(_vm._s(_vm.trans("general.action")))
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "tbody",
                            _vm._l(_vm.products.data, function(product) {
                              return _c("tr", [
                                _c("td", {
                                  domProps: {
                                    textContent: _vm._s(product.ean_number)
                                  }
                                }),
                                _vm._v(" "),
                                _c("td", {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.toWord(product.name)
                                    )
                                  }
                                }),
                                _vm._v(" "),
                                _c("td", {
                                  domProps: {
                                    textContent: _vm._s(
                                      product.supplier
                                        ? _vm.toWord(product.supplier.company)
                                        : "-"
                                    )
                                  }
                                }),
                                _vm._v(" "),
                                _c("td", {
                                  domProps: {
                                    textContent: _vm._s(_vm.getNumber(product))
                                  }
                                }),
                                _vm._v(" "),
                                _c("td", { staticClass: "table-option" }, [
                                  _c("div", { staticClass: "btn-group" }, [
                                    _c(
                                      "button",
                                      {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value: _vm.trans(
                                              "product.edit_product"
                                            ),
                                            expression:
                                              "trans('product.edit_product')"
                                          }
                                        ],
                                        staticClass: "btn btn-info btn-sm",
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.editProduct(product)
                                          }
                                        }
                                      },
                                      [_c("i", { staticClass: "fas fa-edit" })]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        directives: [
                                          {
                                            name: "confirm",
                                            rawName: "v-confirm",
                                            value: {
                                              ok: _vm.confirmDelete(product)
                                            },
                                            expression:
                                              "{ok: confirmDelete(product)}"
                                          },
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value: _vm.trans(
                                              "product.delete_product"
                                            ),
                                            expression:
                                              "trans('product.delete_product')"
                                          }
                                        ],
                                        key: product.id,
                                        staticClass: "btn btn-danger btn-sm"
                                      },
                                      [_c("i", { staticClass: "fas fa-trash" })]
                                    )
                                  ])
                                ])
                              ])
                            }),
                            0
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.products.total
                    ? _c("module-info", {
                        attrs: {
                          module: "product",
                          title: "module_info_title",
                          description: "module_info_description",
                          icon: "key"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("pagination-record", {
                    attrs: {
                      "page-length": _vm.filterProductForm.page_length,
                      records: _vm.products
                    },
                    on: {
                      "update:pageLength": function($event) {
                        return _vm.$set(
                          _vm.filterProductForm,
                          "page_length",
                          $event
                        )
                      },
                      "update:page-length": function($event) {
                        return _vm.$set(
                          _vm.filterProductForm,
                          "page_length",
                          $event
                        )
                      },
                      updateRecords: _vm.getProducts
                    },
                    nativeOn: {
                      change: function($event) {
                        return _vm.getProducts($event)
                      }
                    }
                  })
                ],
                1
              )
            ])
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }