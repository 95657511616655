<template>
    <div>
        <div class="form-group" v-show="!source">
            <span id="fileselector-xls">
                <label class="btn btn-info">
                    <input type="file" @change="checkXLS" :id="id" class="upload-button" accept=".xls,.xlsx">
                    <i class="fas fa-upload margin-correction"></i> {{trans('general.choose_xls')}}
                </label>
            </span>
        </div>
        <div v-show="source">
            <button type="submit" class="btn btn-info waves-effect waves-light" @click="uploadXLS">{{trans('general.upload')}}</button>
            <button type="button" class="btn btn-danger waves-effect waves-light" @click="cancelUploadXLS">{{trans('general.cancel_upload')}}</button>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            id: {
                default: ''
            },
            uploadPath: {
                required: true
            },
            removePath: {
                required: true
            },
            xlsSource: {
                default: ''
            },
            dateSales: {
                required: true
            },
        },
        data() {
            return {
                source: '',
                uploaded: '',
                isRemoved: false,
                xls: ''
            }
        },
        methods: {
            checkXLS(e) {
                
                let files = e.target.files || e.dataTransfer.files;
                let allowedExtensions = /(\.xls|\.xlsx)$/i;
                if(!allowedExtensions.exec($('#'+this.id)[0].files[0].name))
                    toastr.error(i18n.general.file_not_supported);
                else {
                    if (!files.length)
                        return;
                    this.createXLS(files[0]);
                }
                
                /*let file = e.target.files[0];
                
                console.log(file["type"]);
                
                if(file["type"] == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" or file["type"] == "application/vnd.ms-excel"){
                    
                }*/
                
            },
            createXLS(file) {
                let reader = new FileReader();
                reader.onload = (e) => {
                    this.source = e.target.result;
                };
                reader.readAsDataURL(file);
            },
            cancelUploadXLS(){
                this.source = '';
                $("#"+this.id).val('');
            },
            uploadXLS() {
                let data = new FormData();
                data.append('date', this.dateSales.toISOString().slice(0,10));
                data.append('xls', $('#'+this.id)[0].files[0]);
                axios.post('/api'+this.uploadPath,data)
                    .then(response => response.data)
                    .then(response => {
                        toastr.success(response.message);
                        this.source = '';
                        $("#"+this.id).val('');
                        this.uploaded = response.xls;
                        this.$emit('uploaded',response.xls);
                    }).catch(error => {
                        if(error.response.status == 422) {
                            toastr.error(error.response.data.error);
                            this.cancelUploadXLS();
                        }
                        else if(error.response.status == 413 || error.response.status == 500){
                            toastr.error(i18n.general.file_too_large);
                            this.cancelUploadXLS();
                        }
                        else
                            helper.showDataErrorMsg(error);
                    });
            },
            confirmRemove(xlsSource){
                return dialog => this.removeXLS();
            },
            removeXLS() {
                axios.delete('/api'+this.removePath)
                    .then(response => response.data)
                    .then(response => {
                        toastr.success(response.message);
                        this.isRemoved = true;
                        this.uploaded = '';
                        this.$emit('removed','');
                    }).catch(error => {
                        if(error.response.status == 422) {
                            toastr.error(error.response.data.error);
                            this.cancelUploadXLS();
                        }
                        else {
                            helper.showDataErrorMsg(error);
                        }
                    });
            }
        },
        computed: { }
    }
</script>
