<template>
    <div>
        <div class="row page-titles p-3 border-bottom">
            <div class="col-md-12 col-12">
                <button class="btn btn-info pull-right" style="margin-top:-5px;" @click.prevent="back"><i class="fal fa-cash-register"></i> zurück zur Kasse</button>                                
                <h3 class="text-themecolor m-b-0 m-t-0">Kassenübersicht</h3>                              
            </div>
        </div>
        <div class="container-fluid p-40">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="table-responsive table-cash-register-log" v-if="cashregister_bills.total">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>{{trans('cashregister_bill.number')}}</th>
                                            <th>{{trans('cashregister_bill.timestamp')}}</th>
                                            <th>{{trans('cashregister_bill.payment_type')}}</th>
                                            <th>{{trans('cashregister_bill.articles')}}</th>
                                            <th align="right" style="text-align:right;">{{trans('cashregister_bill.amount')}}</th>   
                                            <th class="table-option">{{trans('general.action')}}</th>      
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="cashregister_bill in cashregister_bills.data">
                                            <td v-text="cashregister_bill.number"></td>
                                            <td>{{ cashregister_bill.created_at | moment }}</td>
                                            <td v-text="cashregister_bill.payment_type"></td>
                                            <td>
                                                <ul>
                                                    <li v-for="cashregister_bill_entry in cashregister_bill.cashregister_entries">
                                                        {{ (cashregister_bill_entry.product_id?cashregister_bill_entry.product.name:cashregister_bill_entry.name) + ' (' + cashregister_bill_entry.number + ' x ' + (cashregister_bill_entry.discount?(format_amount(cashregister_bill_entry.price-cashregister_bill_entry.discount) + " / " + format_amount(cashregister_bill_entry.price)):format_amount(cashregister_bill_entry.price)) + ')' }}
                                                    </li>
                                                </ul>
                                            </td>
                                            <td align="right" v-text="format_amount(cashregister_bill.amount_total)"></td>
                                            <td class="table-option">
                                                <div class="btn-group">
                                                    <button class="btn btn-info btn-sm" v-confirm="{ok: confirmPrint(cashregister_bill)}" v-tooltip="trans('cashregister_bill.print_cashregister_bill')"><i class="fas fa-print"></i></button>
                                                    <button class="btn btn-danger btn-sm" :key="cashregister_bill.id" v-confirm="{ok: confirmDelete(cashregister_bill)}" v-tooltip="trans('cashregister_bill.delete_cashregister_bill')"><i class="fas fa-trash"></i></button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>                        
                            <pagination-record :page-length.sync="filterBillForm.page_length" :records="cashregister_bills" @updateRecords="getBills" @change.native="getBills"></pagination-record>
                        </div>
                    </div>
                </div>
            </div>
        </div>        
    </div>
</template>
<script>
    export default {
        data() {
            return {
                filterBillForm: {
                    page_length: helper.getConfig('page_length')
                },
                cashregister_bills: {
                    total: 0,
                    data: []
                }, 
                page_cur: 1
            };
        },
        mounted(){
            if(!helper.hasPermission('manage-cashregister')){
                helper.notAccessibleMsg();
                this.$router.push('/home');
            }
            this.getBills();
        },
        methods: {
            getBills(page){
                if (typeof page !== 'number') {
                    page = 1;
                }
                let url = helper.getFilterURL(this.filterBillForm);
                this.page_cur = page;
                axios.get('/api/cashregister-bill?page=' + page + url)
                    .then(response => response.data)
                    .then(response => {
                            this.cashregister_bills = response;                            
                        }
                    )
                    .catch(error => {
                        helper.showDataErrorMsg(error);
                    });
            },
            back(){
                this.$router.push('/cash-register');
            },
            confirmPrint(cashregister_bill){
                return dialog => this.printCashregisterBill(cashregister_bill);
            },
            printCashregisterBill(cashregister_bill){
                 axios.get('/api/cashregister-bill/'+cashregister_bill.id+'/print')
                    .then(response => response.data)
                    .then(response => {
                               toastr.success(response.message);         
                        }
                    )
                    .catch(error => {
                        helper.showDataErrorMsg(error);
                    });
            },   
            confirmDelete(cashregister_bill){
                return dialog => this.deleteCashregisterBill(cashregister_bill);
            },
            deleteCashregisterBill(cashregister_bill){
                axios.delete('/api/cashregister-bill/'+cashregister_bill.id)
                    .then(response => response.data)
                    .then(response => {
                        toastr.success(response.message);
                        this.getBills(this.page_cur);
                    }).catch(error => {
                        helper.showDataErrorMsg(error);
                    });
            },
            format_amount(amount) {
                return helper.formatAmount(amount);
            }   
            
        },
        filters: {
          moment(date) {
            return helper.formatDateTime(date);
          }
        },
    }
</script>
