var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.source,
            expression: "!source"
          }
        ],
        staticClass: "form-group"
      },
      [
        _c("span", { attrs: { id: "fileselector-xls" } }, [
          _c("label", { staticClass: "btn btn-info" }, [
            _c("input", {
              staticClass: "upload-button",
              attrs: { type: "file", id: _vm.id, accept: ".xls,.xlsx" },
              on: { change: _vm.checkXLS }
            }),
            _vm._v(" "),
            _c("i", { staticClass: "fas fa-upload margin-correction" }),
            _vm._v(
              " " + _vm._s(_vm.trans("general.choose_xls")) + "\n            "
            )
          ])
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.source,
            expression: "source"
          }
        ]
      },
      [
        _c(
          "button",
          {
            staticClass: "btn btn-info waves-effect waves-light",
            attrs: { type: "submit" },
            on: { click: _vm.uploadXLS }
          },
          [_vm._v(_vm._s(_vm.trans("general.upload")))]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-danger waves-effect waves-light",
            attrs: { type: "button" },
            on: { click: _vm.cancelUploadXLS }
          },
          [_vm._v(_vm._s(_vm.trans("general.cancel_upload")))]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }