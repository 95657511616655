var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row page-titles" }, [
      _c("div", { staticClass: "col-md-6 col-8 align-self-center" }, [
        _c("h3", { staticClass: "text-themecolor m-b-0 m-t-0" }),
        _vm._v(" "),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c("router-link", { attrs: { to: "/home" } }, [
                _vm._v(_vm._s(_vm.trans("general.home")))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("li", { staticClass: "breadcrumb-item active" }, [
            _vm._v(_vm._s(_vm.trans("supplier.suppliers")))
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "container-fluid p-40" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-10 col-lg-10 col-md-10" }, [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-12 col-sm-4 col-md-4" },
                      [
                        _c("h4", { staticClass: "card-title" }, [
                          _vm._v(_vm._s(_vm.trans("supplier.add_new_supplier")))
                        ]),
                        _vm._v(" "),
                        _c("supplier-form", {
                          on: { completed: _vm.getSuppliers }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-12 col-sm-8 col-md-8" },
                      [
                        _c("h4", { staticClass: "card-title" }, [
                          _vm._v(_vm._s(_vm.trans("supplier.supplier_list")))
                        ]),
                        _vm._v(" "),
                        _vm.suppliers
                          ? _c("h6", { staticClass: "card-subtitle" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.trans("general.total_result_found", {
                                    count: _vm.suppliers.total
                                  })
                                )
                              )
                            ])
                          : _c("h6", { staticClass: "card-subtitle" }, [
                              _vm._v(
                                _vm._s(_vm.trans("general.no_result_found"))
                              )
                            ]),
                        _vm._v(" "),
                        _vm.suppliers.total
                          ? _c("div", { staticClass: "table-responsive" }, [
                              _c("table", { staticClass: "table" }, [
                                _c("thead", [
                                  _c("tr", [
                                    _c("th", [
                                      _vm._v(
                                        _vm._s(_vm.trans("supplier.company"))
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("th", { staticClass: "table-option" }, [
                                      _vm._v("Action")
                                    ])
                                  ])
                                ]),
                                _vm._v(" "),
                                _c(
                                  "tbody",
                                  _vm._l(_vm.suppliers.data, function(
                                    supplier
                                  ) {
                                    return _c("tr", [
                                      _c("td", {
                                        domProps: {
                                          textContent: _vm._s(supplier.company)
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        { staticClass: "table-option" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "btn-group" },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "confirm",
                                                      rawName: "v-confirm",
                                                      value: {
                                                        ok: _vm.confirmDelete(
                                                          supplier
                                                        )
                                                      },
                                                      expression:
                                                        "{ok: confirmDelete(supplier)}"
                                                    },
                                                    {
                                                      name: "tooltip",
                                                      rawName: "v-tooltip",
                                                      value: _vm.trans(
                                                        "supplier.delete_supplier"
                                                      ),
                                                      expression:
                                                        "trans('supplier.delete_supplier')"
                                                    }
                                                  ],
                                                  key: supplier.id,
                                                  staticClass:
                                                    "btn btn-danger btn-sm"
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "fas fa-trash"
                                                  })
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ])
                                  }),
                                  0
                                )
                              ])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.suppliers.total
                          ? _c("module-info", {
                              attrs: {
                                module: "supplier",
                                title: "module_info_title",
                                description: "module_info_description",
                                icon: "key"
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("pagination-record", {
                          attrs: {
                            "page-length": _vm.filterSupplierForm.page_length,
                            records: _vm.suppliers
                          },
                          on: {
                            "update:pageLength": function($event) {
                              return _vm.$set(
                                _vm.filterSupplierForm,
                                "page_length",
                                $event
                              )
                            },
                            "update:page-length": function($event) {
                              return _vm.$set(
                                _vm.filterSupplierForm,
                                "page_length",
                                $event
                              )
                            },
                            updateRecords: _vm.getSuppliers
                          },
                          nativeOn: {
                            change: function($event) {
                              return _vm.getSuppliers($event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }