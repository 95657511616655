var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.storeSalesImport($event)
        },
        keydown: function($event) {
          return _vm.salesImportForm.errors.clear($event.target.name)
        }
      }
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("product.date_sale")))
              ]),
              _vm._v(" "),
              _c("datepicker", {
                attrs: {
                  bootstrapStyling: true,
                  disabledDates: _vm.disabledFn
                },
                on: {
                  selected: function($event) {
                    return _vm.salesImportForm.errors.clear("date")
                  }
                },
                model: {
                  value: _vm.salesImportForm.date,
                  callback: function($$v) {
                    _vm.$set(_vm.salesImportForm, "date", $$v)
                  },
                  expression: "salesImportForm.date"
                }
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: { "form-name": _vm.salesImportForm, "prop-name": "date" }
              })
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _vm.salesImportForm.date
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-12" },
              [
                _c("upload-xls", {
                  attrs: {
                    id: "xls",
                    "date-sales": _vm.salesImportForm.date,
                    "upload-path": "/product/import-xls",
                    "remove-path": "/product/import-xls/remove"
                  },
                  on: { uploaded: _vm.updateXLS, removed: _vm.updateXLS }
                })
              ],
              1
            )
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }