<template>
    <div>
        <div class="form-group text-center m-t-20">
            <span id="fileselector" v-show="!source">
                <label class="btn btn-info">
                    <input type="file" @change="checkCSV" :id="id" class="upload-button">
                    <i class="fas fa-upload margin-correction"></i> {{trans('general.choose_csv')}}
                </label>
            </span>
        </div>
        <div class="text-center">
            <button type="submit" class="btn btn-info waves-effect waves-light m-t-10" v-if="source" @click="uploadCSV">{{trans('general.upload')}}</button>
            <button type="button" class="btn btn-danger waves-effect waves-light m-t-10" v-if="source" @click="cancelUploadCSV">{{trans('general.cancel_upload')}}</button>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            id: {
                default: ''
            },
            uploadPath: {
                required: true
            },
            removePath: {
                required: true
            },
            csvSource: {
                default: ''
            }
        },
        data() {
            return {
                source: '',
                uploaded: '',
                isRemoved: false,
                csv: ''
            }
        },
        methods: {
            checkCSV(e) {
                
            },
            createCSV(file) {
                let reader = new FileReader();
                reader.onload = (e) => {
                    this.source = e.target.result;
                };
                reader.readAsDataURL(file);
            },
            cancelUploadCSV(){
                this.source = '';
                $("#"+this.id).val('');
            },
            uploadCSV() {
                let data = new FormData();
                data.append('csv', $('#'+this.id)[0].files[0]);
                axios.post('/api'+this.uploadPath,data)
                    .then(response => response.data)
                    .then(response => {
                        toastr.success(response.message);
                        this.source = '';
                        $("#"+this.id).val('');
                        this.uploaded = response.csv;
                        this.$emit('uploaded',response.csv);
                    }).catch(error => {
                        if(error.response.status == 422) {
                            toastr.error(error.response.data.error);
                            this.cancelUploadCSV();
                        }
                        else if(error.response.status == 413 || error.response.status == 500){
                            toastr.error(i18n.general.file_too_large);
                            this.cancelUploadCSV();
                        }
                        else
                            helper.showDataErrorMsg(error);
                    });
            },
            confirmRemove(csvSource){
                return dialog => this.removeCSV();
            },
            removeCSV() {
                axios.delete('/api'+this.removePath)
                    .then(response => response.data)
                    .then(response => {
                        toastr.success(response.message);
                        this.isRemoved = true;
                        this.uploaded = '';
                        this.$emit('removed','');
                    }).catch(error => {
                        if(error.response.status == 422) {
                            toastr.error(error.response.data.error);
                            this.cancelUploadCSV();
                        }
                        else {
                            helper.showDataErrorMsg(error);
                        }
                    });
            }
        },
        computed: { }
    }
</script>
