<template>
    <form @submit.prevent="exportPurchasingList" @keydown="exportPurchasingForm.errors.clear($event.target.name)">
         <div class="row">
            <div class="col-md-12">
                 <div class="form-group">
                    <label class="control-label">{{trans('supplier.supplier')}}</label>
                    <select class="form-control custom-select" v-model="exportPurchasingForm.supplier_id">
                        <option value="0" v-if="!exportPurchasingForm.supplier_id&&suppliers.length">Wählen Sie einen Lieferanten</option>
                        <option value="0" v-if="!suppliers.length">Keine Lieferanten vorhanden</option>
                        <option :value="supplier.id" v-for="supplier in suppliers">{{ supplier.company }}</option>
                    </select>
                    <show-error :form-name="exportPurchasingForm" prop-name="supplier_id"></show-error>
                </div>
            </div>
            <div class="col-md-12">
                <div class="form-group">
                    <date-range-picker :start-date.sync="exportPurchasingForm.created_at_start_date" :end-date.sync="exportPurchasingForm.created_at_end_date"></date-range-picker>
                </div>
            </div>
         </div>       
        <div class="row">
            <div class="col-md-12">
                 <a :href="getDownloadLink()" class="btn btn-info waves-effect waves-light pull-right"><span>{{trans('general.export')}}</span></a> 
            </div>
        </div>       
    </form>
</template>
<script>
    import dateRangePicker from '../../components/date-range-picker'
    export default {
        components: {dateRangePicker},    
        data() {
            return {
                exportPurchasingForm: new Form({
                    supplier_id : 0,
                    created_at_start_date: '',
                    created_at_end_date: '',
                }),
                suppliers: [],
            };
        },
        mounted(){
            this.getSuppliers();         
        },
        methods: {
            exportPurchasingList(){
                axios.get('/product/export-purchasing-list/' + this.exportPurchasingForm.supplier_id)
                    .then(response => response.data)
                    .then(response => {
                        toastr.success(response.message);                       
                    })
                    .catch(error => {
                        helper.showErrorMsg(error);
                    });
            },
             getSuppliers(){
                axios.get('/api/supplier')
                    .then(response => response.data)
                    .then(response => {   
                        this.suppliers = response.data;  
                    })
                    .catch(error => {
                        helper.showDataErrorMsg(error)
                    });
            },
            getDownloadLink(){
                return '/product/export-purchasing-list/' + this.exportPurchasingForm.supplier_id + '?token='+localStorage.getItem('auth_token') + '&from=' + this.exportPurchasingForm.created_at_start_date + '&until=' + this.exportPurchasingForm.created_at_end_date;
            },
           
        }
    }
</script>
