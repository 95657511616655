var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "page-titles p-3 border-bottom" }, [
      _c("h3", { staticClass: "text-themecolor" }, [
        _vm._v(_vm._s(_vm.trans("import.import")) + "\n            "),
        _c(
          "button",
          {
            staticClass: "btn btn-info btn-sm pull-right",
            on: {
              click: function($event) {
                return _vm.$router.push("/home")
              }
            }
          },
          [
            _c("i", { staticClass: "fas fa-home" }),
            _vm._v(" "),
            _c("span", { staticClass: "d-none d-sm-inline" }, [
              _vm._v(_vm._s(_vm.trans("general.home")))
            ])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "container-fluid p-40" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body p-4" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-12 col-sm-6" },
                  [
                    _c("h4", { staticClass: "card-title" }, [
                      _vm._v(_vm._s(_vm.trans("import.import_new")))
                    ]),
                    _vm._v(" "),
                    _c("upload-csv", {
                      attrs: {
                        id: "csv",
                        "upload-path": "/import",
                        "remove-path": "/import/remove"
                      },
                      on: { uploaded: _vm.updateCSV, removed: _vm.updateCSV }
                    })
                  ],
                  1
                )
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }