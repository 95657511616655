var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "form-product",
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.proceed($event)
        },
        keydown: function($event) {
          return _vm.productForm.errors.clear($event.target.name)
        }
      }
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-4" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("product.name")))
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.productForm.name,
                    expression: "productForm.name"
                  }
                ],
                staticClass: "form-control",
                attrs: { type: "text", value: "", name: "name" },
                domProps: { value: _vm.productForm.name },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.productForm, "name", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: { "form-name": _vm.productForm, "prop-name": "name" }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-4" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { staticClass: "control-label" }, [
                _vm._v(_vm._s(_vm.trans("supplier.supplier")))
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.productForm.supplier_id,
                      expression: "productForm.supplier_id"
                    }
                  ],
                  staticClass: "form-control custom-select",
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.productForm,
                        "supplier_id",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                [
                  !_vm.productForm.supplier_id && _vm.suppliers.length
                    ? _c("option", { attrs: { value: "" } }, [
                        _vm._v("Wählen Sie einen Lieferanten")
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.suppliers.length
                    ? _c("option", { attrs: { value: "" } }, [
                        _vm._v("Keine Lieferanten vorhanden")
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.suppliers, function(supplier) {
                    return _c("option", { domProps: { value: supplier.id } }, [
                      _vm._v(_vm._s(supplier.company))
                    ])
                  })
                ],
                2
              ),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.productForm,
                  "prop-name": "supplier_id"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-4" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("product.ean_number")))
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.productForm.ean_number,
                    expression: "productForm.ean_number"
                  }
                ],
                staticClass: "form-control",
                attrs: { type: "text", value: "", name: "name" },
                domProps: { value: _vm.productForm.ean_number },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.productForm, "ean_number", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.productForm,
                  "prop-name": "ean_number"
                }
              })
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-2" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("product.price_order")))
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.productForm.price_order,
                    expression: "productForm.price_order"
                  }
                ],
                staticClass: "form-control",
                attrs: { type: "text", value: "", name: "price_order" },
                domProps: { value: _vm.productForm.price_order },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.productForm,
                      "price_order",
                      $event.target.value
                    )
                  }
                }
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.productForm,
                  "prop-name": "price_order"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-2" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("product.price_shop")))
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.productForm.price_shop,
                    expression: "productForm.price_shop"
                  }
                ],
                staticClass: "form-control",
                attrs: { type: "text", value: "", name: "price_shop" },
                domProps: { value: _vm.productForm.price_shop },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.productForm, "price_shop", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.productForm,
                  "prop-name": "price_shop"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-2" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("product.stock_min")))
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.productForm.stock_min,
                    expression: "productForm.stock_min"
                  }
                ],
                staticClass: "form-control",
                attrs: { type: "text", value: "", name: "stock_min" },
                domProps: { value: _vm.productForm.stock_min },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.productForm, "stock_min", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.productForm,
                  "prop-name": "stock_min"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-2" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("product.order_min")))
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.productForm.order_min,
                    expression: "productForm.order_min"
                  }
                ],
                staticClass: "form-control",
                attrs: { type: "text", value: "", name: "order_min" },
                domProps: { value: _vm.productForm.order_min },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.productForm, "order_min", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.productForm,
                  "prop-name": "order_min"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-2" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("product.packaging_unit")))
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.productForm.packaging_unit,
                    expression: "productForm.packaging_unit"
                  }
                ],
                staticClass: "form-control",
                attrs: { type: "text", value: "", name: "packaging_unit" },
                domProps: { value: _vm.productForm.packaging_unit },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.productForm,
                      "packaging_unit",
                      $event.target.value
                    )
                  }
                }
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.productForm,
                  "prop-name": "packaging_unit"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-2" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("product.expirated_at")))
              ]),
              _vm._v(" "),
              _c("datepicker", {
                attrs: { "clear-button": true, bootstrapStyling: true },
                on: {
                  selected: function($event) {
                    return _vm.productForm.errors.clear("expirated_at")
                  }
                },
                model: {
                  value: _vm.productForm.expirated_at,
                  callback: function($$v) {
                    _vm.$set(_vm.productForm, "expirated_at", $$v)
                  },
                  expression: "productForm.expirated_at"
                }
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.productForm,
                  "prop-name": "expirated_at"
                }
              })
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-2" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("product.multipack_ean_number")))
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.productForm.multipack_ean_number,
                    expression: "productForm.multipack_ean_number"
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  value: "",
                  name: "multipack_ean_number"
                },
                domProps: { value: _vm.productForm.multipack_ean_number },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.productForm,
                      "multipack_ean_number",
                      $event.target.value
                    )
                  }
                }
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.productForm,
                  "prop-name": "multipack_ean_number"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-2" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("product.multipack_number")))
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.productForm.multipack_number,
                    expression: "productForm.multipack_number"
                  }
                ],
                staticClass: "form-control",
                attrs: { type: "text", value: "", name: "multipack_number" },
                domProps: { value: _vm.productForm.multipack_number },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.productForm,
                      "multipack_number",
                      $event.target.value
                    )
                  }
                }
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.productForm,
                  "prop-name": "multipack_number"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-6" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { staticClass: "control-label" }, [
                _vm._v(_vm._s(_vm.trans("product.category")))
              ]),
              _vm._v(" "),
              _c("v-select", {
                attrs: {
                  label: "name",
                  "track-by": "id",
                  name: "category_id",
                  id: "category_id",
                  options: _vm.categories,
                  placeholder: _vm.trans("product.no_category"),
                  multiple: false
                },
                model: {
                  value: _vm.productForm.category_id,
                  callback: function($$v) {
                    _vm.$set(_vm.productForm, "category_id", $$v)
                  },
                  expression: "productForm.category_id"
                }
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.productForm,
                  "prop-name": "category_id"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-2" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c(
                "label",
                { staticStyle: { display: "block" }, attrs: { for: "" } },
                [_vm._v("Bestandsprüfung")]
              ),
              _vm._v(" "),
              _c("switches", {
                attrs: { theme: "bootstrap", color: "success", label: "" },
                model: {
                  value: _vm.productForm.is_inventory_check,
                  callback: function($$v) {
                    _vm.$set(_vm.productForm, "is_inventory_check", $$v)
                  },
                  expression: "productForm.is_inventory_check"
                }
              })
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c(
              "button",
              {
                staticClass: "btn btn-info waves-effect waves-light pull-right",
                attrs: { type: "submit" }
              },
              [
                _vm.id
                  ? _c("span", [_vm._v(_vm._s(_vm.trans("general.update")))])
                  : _c("span", [_vm._v(_vm._s(_vm.trans("general.save")))])
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.id,
                    expression: "id"
                  }
                ],
                staticClass:
                  "btn btn-danger waves-effect waves-light pull-right m-r-10",
                attrs: { to: "/products" }
              },
              [_vm._v(_vm._s(_vm.trans("general.cancel")))]
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }